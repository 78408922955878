import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Spin, Space, Row, Col, Input, Tag, Tooltip} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {MdAddHome} from "react-icons/md";
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {CommentOutlined, DollarOutlined, HistoryOutlined} from '@ant-design/icons';
import {IoReload, IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import Search from "antd/es/input/Search";
import _ from "lodash";
import {CloseCircleOutlined} from "@ant-design/icons";
import {CheckCircleOutlined} from "@ant-design/icons";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

// const { Option } = Select;

const ViajePersonalizado = () => {

    if(sessionStorage.getItem('user_mod'))
       sessionStorage.removeItem('user_mod');

    const [bottom, setBottom] = useState('bottomCenter');

    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);

    const [idusuarioSession, setIdusuarioSession] = useState([]);

    const [form] = Form.useForm();

    const { TextArea } = Input;

    const navigate = useNavigate();

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Cargando datos...");

    const [messageApi, contextHolder] = message.useMessage();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading] = useState(false);

    const [usuarioSession, setUsuarioSession] = useState([]);

    const [visibleRechazar, setVisibleRechazar] = useState(false);
    const [observaciones, setObservaciones] = useState('');

    const [visibleCancel, setVisibleCancel] = useState(false);
    const [observacion, setObservacion] = useState('');

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        /*{
            title: 'Categoría Servicio',
            dataIndex: 'categoriaservicio',
            fixed: 'left',
            width: 160,
        },*/{
            title: 'Estado Solicitud',
            dataIndex: 'estadosolicitud',
            fixed: 'left',
            width: 170,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.estadosolicitud[0]), _.isEqual),
            onFilter: (value, record) => record.estadosolicitud[0].startsWith(value),
            render: (tags) => (
                <span>
              {tags.map((tag) => {
                  let color;
                  switch (tag) {
                      case 'Solicitada':
                          color = '#51cf66';
                          break;
                      case 'Rechazada':
                          color = '#9E4624';
                          break;
                      case 'Cancelada':
                          color = '#D2691E';
                          break;
                      case 'Planificada':
                          color = '#51cf66';
                          break;
                      case 'Ofertada':
                          color = '#5c7cfa';
                          break;
                      case 'Confirmada':
                            color = '#B565A7';
                            break;
                      default:
                          color = '#ff8c00';
                  }
                  return (
                      <Tag color={color} key={tag}>
                          {tag.toUpperCase()}
                      </Tag>
                  );
              })}
            </span>
            ),
        },{
            title: 'Estado Oferta',
            dataIndex: 'estadooferta',
            fixed: 'left',
            width: 140,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.estadooferta[0]), _.isEqual),
            onFilter: (value, record) => record.estadooferta[0].startsWith(value),
            render: (tags) => (
                <span>
              {tags.map((tag) => {
                  let color;
                  switch (tag) {
                      case 'Aceptada':
                          color = '#51cf66';
                          break;
                      case 'Ofertada':
                          color = '#5c7cfa';
                          break;
                      case 'Rechazada':
                          color = '#f03e3e';
                          break;
                      case 'Confirmada':
                            color = '#B565A7';
                            break;
                      default:
                          color = '#ff8c00';
                  }
                  return (
                      <Tag color={color} key={tag}>
                          {tag.toUpperCase()}
                      </Tag>
                  );
              })}
            </span>
            ),
        },{
            title: 'Estado Pago',
            dataIndex: 'estadopago',
            fixed: 'left',
            width: 140,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.estadopago[0]), _.isEqual),
            onFilter: (value, record) => record.estadopago[0].startsWith(value),
            render: (tags) => (
                <span>
              {tags.map((tag) => {
                  let color;
                  switch (tag) {
                      case 'Pagado':
                          color = '#51cf66';
                          break;
                      case 'Reembolsado':
                          color = '#5c7cfa';
                          break;
                      case 'Vencido':
                          color = '#f03e3e';
                          break;
                      default:
                          color = '#ff8c00';
                  }
                  return (
                      <Tag color={color} key={tag}>
                          {tag.toUpperCase()}
                      </Tag>
                  );
              })}
            </span>
            ),
        },{
            title: 'Link de Pago',
            dataIndex: 'rutapago',
            width: 100,
            render: (text) => (text !== null)?<IoCheckmarkCircle size={20} color={'green'}/>:'',
            // render: (text) => (text !== null)?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Fecha Solicitud',
            dataIndex: 'fecharegistro',
            filterSearch: true,
            width: 140,
            filters: _.uniqWith(filterData(data)(i => i.fecharegistro), _.isEqual),
            onFilter: (value, record) => record.fecharegistro.startsWith(value)
        },{
            title: 'Origen',
            dataIndex: 'origen',
            filterSearch: true,
            width: 100,
            filters: _.uniqWith(filterData(data)(i => i.origen), _.isEqual),
            onFilter: (value, record) => record.origen.startsWith(value)
        },{
            title: 'Destino',
            dataIndex: 'destino',
            filterSearch: true,
            width: 100,
            filters: _.uniqWith(filterData(data)(i => i.destino), _.isEqual),
            onFilter: (value, record) => record.destino.startsWith(value)
        },{
            title: 'Fecha Viaje',
            dataIndex: 'fechaviaje',
            filterSearch: true,
            width: 120,
            filters: _.uniqWith(filterData(data)(i => i.fechaviaje), _.isEqual),
            onFilter: (value, record) => record.fechaviaje.startsWith(value)
        },{
            title: 'Hora Salida',
            dataIndex: 'horasalida',
            filterSearch: true,
            width: 110,
            filters: _.uniqWith(filterData(data)(i => i.horasalida), _.isEqual),
            onFilter: (value, record) => record.horasalida.startsWith(value)
        },{
            title: 'Tipo Transporte',
            dataIndex: 'tipotransporte',
            filterSearch: true,
            width: 140,
            filters: _.uniqWith(filterData(data)(i => i.tipotransporte), _.isEqual),
            onFilter: (value, record) => record.tipotransporte.startsWith(value),
        },{
            title: 'Niños',
            dataIndex: 'cantninnos',
            filterSearch: true,
            width: 100,
            filters: _.uniqWith(filterData(data)(i => i.cantninnos), _.isEqual),
            onFilter: (value, record) => record.cantninnos.startsWith(value)
        },{
            title: 'Adultos',
            dataIndex: 'cantadultos',
            filterSearch: true,
            width: 100,
            filters: _.uniqWith(filterData(data)(i => i.cantadultos), _.isEqual),
            onFilter: (value, record) => record.cantadultos.startsWith(value)
        },{
            title: 'Silla de Ruedas',
            dataIndex: 'cantsillaruedas',
            width: 180,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cantsillaruedas), _.isEqual),
            onFilter: (value, record) => record.cantsillaruedas.startsWith(value)
        },{
            title: 'Importe Oferta',
            dataIndex: 'importeoferta',
            fixed: 'right',
            width: 130,
            render: (tags) => (
                <span>
              {tags.map((tag) => {
                  let color= '#834e56';
                  tag = '$'+' '+tag;
                  return (
                      <Tag color={color} key={tag}>
                          {tag.toUpperCase()}
                      </Tag>
                  );
              })}
            </span>
            ),
        },{
            title: 'Observación Solicitud',
            dataIndex: 'observacionsolicitud',
            //fixed: 'left',
            width: 160,
        },{
            title: 'Viaje Terminado',
            dataIndex: 'viajeterminado',
            width: 140,
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Solicita',
            dataIndex: 'correoregistro',
            width: 230,
        },{
            title: 'Teléfono',
            dataIndex: 'telefonousuariosolic',
            width: 100,
        }/*,{
        title: 'Fecha Solicitud',
        dataIndex: 'fecharegistro',
    }*/
    ];

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    useEffect(() => {
        let info_user = JSON.parse(sessionStorage.getItem('info_user'));
        setUsuarioSession(info_user);
        recargar(info_user);
    }, []);

    const recargar = (info_user) => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        // let start = 0;
        // let limit = 1000;

        if(!info_user.idusuario){
            info_user.idusuario = JSON.parse(sessionStorage.getItem('info_user')).idusuario;
        }

        Axios.post("ht/getSolicitudViaje",
            {
                "idcategoriaservicio": 1,
                "idusuariocrea": info_user.idusuario
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            // console.log('usuarios', response.data);
            if(response.data.data.length>0){
                for(let i=0;i<response.data.data.length;i++){
                    aux.push({
                        key:response.data.data[i].idsolicitudviaje,
                        idsolicitudviaje:response.data.data[i].idsolicitudviaje,
                        idcategoriaservicio:response.data.data[i].idcategoriaservicio,
                        categoriaservicio:response.data.data[i].categoriaservicio,
                        idviajecharterfecha:response.data.data[i].idviajecharterfecha,
                        origen:response.data.data[i].origen,
                        destino:response.data.data[i].destino,
                        fechaviaje:response.data.data[i].fechaviaje,
                        horasalida:response.data.data[i].horasalida,
                        fecharegistro:response.data.data[i].fecharegistro,
                        correoregistro:response.data.data[i].correoregistro,
                        telefonousuariosolic:response.data.data[i].telefonousuariosolic,
                        tipotransporte:response.data.data[i].tipotransporte,
                        idtipotransporte:response.data.data[i].idtipotransporte,
                        cantninnos:response.data.data[i].cantninnos,
                        cantadultos:response.data.data[i].cantadultos,
                        cantsillaruedas:response.data.data[i].cantsillaruedas,
                        caracteristicaomnibus:response.data.data[i].caracteristicaomnibus.replace(/"/g, ''),
                        //datos de la oferta
                        estadooferta: [response.data.data[i].estadooferta],
                        importeoferta:[response.data.data[i].importeoferta],
                        idsolicitudofertaspago:response.data.data[i].idsolicitudofertaspago,
                        idestadoofertapago:response.data.data[i].idestadoofertapago,
                        estadopago:[response.data.data[i].estadopago],
                        idestadopago:response.data.data[i].idestadopago,
                        //estadopago:response.data.data[i].estadopago,
                        observacionesodertapago:response.data.data[i].observacionesodertapago,
                        fecharegistrooferta:response.data.data[i].fecharegistrooferta,
                        idestadosolicitud:response.data.data[i].idestadosolicitud,
                        estadosolicitud:[response.data.data[i].estadosolicitud],
                        observacionsolicitud:response.data.data[i].observacionsolicitud,
                        viajeterminado:response.data.data[i].viajeterminado,
                        rutapago:response.data.data[i].rutapago,
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        }).catch(error => {
                setSpinning(false);
            });
    };

    const getInfoForm = async (id) => {
        for(let i=0;i<data.length;i++){
            if(data[i].idsolicitudviaje === id){
                return data[i];
            }
        }
        return [];
    };

    const modificar = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        sessionStorage.setItem('dataSessionStorage', JSON.stringify(row));
        sessionStorage.setItem('ofertas_mod', JSON.stringify(row));
    };

    const pagarViaje = async () => {

        const selectedRow = data.find(row => row.key === selectedRowKeys[0]);

        if (selectedRow && selectedRow.rutapago) {
            setSpinTitle("Procesando datos...");
            setSpinning(true);
            // Redirigir a la plataforma Stripe con la ruta de pago
            window.location.href = selectedRow.rutapago;
        } else {
            messageApi.open({
                type: 'error',
                content: 'No hay una ruta de pago disponible para esta solicitud.',
                duration: 2,
            });


        }

    };

    const eliminar = (e) => {
        Modal.confirm({
            title: 'Eliminar datos!',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea eliminar los datos seleccionados?</span>
                </div>
            ),
            onOk() {
                setSpinTitle("Eliminando datos...");
                setSpinning(true);
                Axios.delete('ht/deleteSolicitudViaje',
                    {
                      data: {
                        "idsolicitudviaje": selectedRowKeys
                      },
                      headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar(usuarioSession);
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);

                    });
            },
        });
    };


    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            let aux = [];
            original.forEach(function(option) {
                if (option.fechaviaje.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };

    // const [aceptarOfertaButtonDisabled, setAceptarOfertaButtonDisabled] = useState(true);
    const [comentarioButtonDisabled, setComentarioButtonDisabled] = useState(true);
    const [pagarButtonDisabled, setPagarButtonDisabled] = useState(true);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        if (newSelectedRowKeys.length === 1) {
            const selectedRow = data.find(row => row.key === newSelectedRowKeys[0]);
            if (selectedRow) {
                // setAceptarOfertaButtonDisabled(selectedRow.idestadoofertapago == 3 || selectedRow.idestadoofertapago == 4);
                setComentarioButtonDisabled(selectedRow.viajeterminado != 1);
                setPagarButtonDisabled(!selectedRow.rutapago || selectedRow.idestadoofertapago == 4);
            }
        }else{
            // setAceptarOfertaButtonDisabled(true);
            setComentarioButtonDisabled(true);
            setPagarButtonDisabled(true);
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const selectRowUpdate  = async (record) => {
        setSelectedRowKeys([record.key]);
        form.resetFields();
        let row = [];
        for(let i=0;i<data.length;i++){
            if(data[i].idsolicitudviaje === record.key){
                row = data[i];
            }
        }

        // console.log('ROW : ', row);
       
        sessionStorage.setItem('user_mod', JSON.stringify(row));
        navigate(`/editviajepersonalizado/${record.key}`)
        
    };

    const showCancelModal = () => {
        setObservacion('');
        setVisibleCancel(true);
    };

    const handleCancelarSolicitud = () => {
        setVisibleCancel(false);
    };

    const handleOkSolicitud = () => {
        setVisibleCancel(false);

        Axios.post("ht/cancelarSolicitud",
            {
                "idsolicitudviaje": selectedRowKeys[0],
                "idestadosolicitud": 2, // cancelada por el Usuario [2]
                "observacion": observacion,
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

            }else{
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
            .catch(error => {
                setSpinning(false);
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

            });

        recargar(usuarioSession);

    };

    const aceptarOferta = async () => {
        const row = await getInfoForm(selectedRowKeys[0]);
        // console.log(row.idestadoofertapago);


        if(row.idestadoofertapago == 1){

            Modal.confirm({
                title: 'Aceptar Oferta!',
                cancelText:'Cancelar',
                okText:'Aceptar',
                okType:'primary',
                content: (
                    <div>
                        <span >¿Desea aceptar la oferta?</span>
                    </div>
                ),
                onOk() {
                    setSpinTitle("Enviando datos...");
                    setSpinning(true);

                    Axios.post("ht/aceptarOfertaUsuario",
                        {
                            "idsolicitudofertaspago": row.idsolicitudofertaspago,
                        },
                        {
                            headers: headers
                        }
                    ).then(response => {
                        setSpinning(false);
                        if(response.data.success){
                            messageApi.open({
                                type: 'success',
                                content: response.data.message,
                                duration: 0,
                            });

                            setTimeout(messageApi.destroy, 2500);

                        }else{
                            messageApi.open({
                                type: 'error',
                                content: 'Error al guardar los datos',
                                duration: 0,
                            });

                            setTimeout(messageApi.destroy, 2500);
                        }
                    }).catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al guardar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);

                    });

                    recargar(usuarioSession);
                },
            });

        }else {
            Modal.info({
                title: 'Información!',
                okText:'Ok',
                okType:'primary',
                content: (
                    <div>
                        <span >Esta solicitud no ha sido ofertada.</span>
                    </div>
                ),
                onOk() {},
            });
        }
    };

    const rechazarOferta = () => {
        setObservaciones('');
        setVisibleRechazar(true);
    };

    const handleRechazarOferta = () => {
        setVisibleRechazar(false);
    };

    const handleOkRechazar = async () => {
        setVisibleRechazar(false);

        const row = await getInfoForm(selectedRowKeys[0]);

        Axios.post("ht/rechazarOfertaUsuario",
            {
                "idsolicitudofertaspago": row.idsolicitudofertaspago,
                "observaciones": observaciones,
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

            }else{
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
            .catch(error => {
                setSpinning(false);
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

            });

        recargar(usuarioSession);

    };


    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
        <div>
            {/*<h2 style={{textAlign:"center"}}>Configurar Ruta Charter</h2>*/}
            <p style={{textAlign:"center", margin:0, fontSize:'20px', fontWeight:'bold'}}>Personalizados</p>
            {contextHolder}
            <div
                style={{
                    marginBottom: 10,
                }}
            >
                {contextHolder}
                <Space direction='horizontal'>
                    <Link to="/addviajepersonalizado">
                        <Tooltip title="Solicitar Viaje">
                            <Button style={{marginRight:"0px", marginLeft:"10px"}} type="primary" shape="circle" className="button" icon={<MdAddHome />} disabled={loading}>
                                {/*Solicitar Viaje*/}
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to={`/editviajepersonalizado/${selectedRowKeys[0]}`}>
                        <Tooltip title="Modificar Solicitud">
                            <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" className="button" icon={<AiFillEdit />} onClick={modificar} disabled={loading || selectedRowKeys.length !== 1}>
                                {/*Modificar Solicitud*/}
                            </Button>
                        </Tooltip>
                    </Link>
                    <Tooltip title="Eliminar Solicitud">
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" danger className="button" icon={<AiFillDelete />} onClick={eliminar}
                                disabled={!hasSelected || loading}>
                            {/*Eliminar Solicitud*/}
                        </Button>
                    </Tooltip>
                    <Tooltip title="Cancelar Solicitud">
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" danger className="button" icon={<CloseCircleOutlined />} onClick={showCancelModal} disabled={loading || selectedRowKeys.length !== 1} >
                            {/*Cancelar Solicitud*/}
                        </Button>
                    </Tooltip>
                    <Tooltip title="Aceptar Oferta">
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" className="button" icon={<CheckCircleOutlined />} onClick={aceptarOferta} disabled={loading || selectedRowKeys.length !== 1}>
                            {/*Aceptar Oferta*/}
                        </Button>
                    </Tooltip>

                    <Tooltip title="Rechazar Oferta">
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" danger className="button" icon={<CloseCircleOutlined />} onClick={rechazarOferta} disabled={loading || selectedRowKeys.length !== 1} >
                            {/*Rechazar Oferta*/}
                        </Button>
                    </Tooltip>

                    <Tooltip title="Pagar Viaje">
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" className="button" icon={<DollarOutlined />} onClick={pagarViaje} disabled={pagarButtonDisabled}>
                            {/* Generar Oferta */}
                        </Button>
                    </Tooltip>

                    <Link to="/comentariosviaje">
                        <Tooltip title="Comentarios">
                            <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" icon={<CommentOutlined />} onClick={modificar} disabled={comentarioButtonDisabled}>
                            </Button>
                        </Tooltip>
                    </Link>

                    <Link to="/verhistorial">
                        <Tooltip title="Ver Historial Ofertas">
                            <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" className="button" icon={<HistoryOutlined />} onClick={modificar} disabled={loading || selectedRowKeys.length !== 1}>
                            </Button>
                        </Tooltip>
                    </Link>

                    {/*</Link>*/}
                    <Tooltip title="Actualizar">
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" className="button" icon={<IoReload />} onClick={recargar} loading={loading || loading}>
                            {/* Recargar */}
                        </Button>
                    </Tooltip>

                    <Search
                        placeholder="Fecha Viaje"
                        enterButton
                        style={{
                            width: 160,
                        }}
                        size="middle"
                        onSearch={onSearch}
                        onChange={(e) => {
                            filterLocal(e.target.value);
                        }}
                    />
                </Space>
            </div>

            <Modal
                title="Cancelar Solicitud"
                visible={visibleCancel}
                onOk={handleOkSolicitud}
                onCancel={handleCancelarSolicitud}
                style={{ textAlign: 'center' }}
                centered
                okButtonProps={{ disabled: observacion.trim() === '' }}
            >
                <p>Observación:</p>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextArea
                        placeholder="Escribe aquí..."
                        rows={4}
                        style={{
                            height: 100,
                            resize: 'none',
                        }}
                        value={observacion}
                        onChange={(e) => setObservacion(e.target.value)}
                        // onChange={onChangeCancelSolicitud}
                    />
                </div>
            </Modal>

            <Modal
                title="Rechazar Oferta"
                visible={visibleRechazar}
                onOk={handleOkRechazar}
                onCancel={handleRechazarOferta}
                style={{ textAlign: 'center' }}
                centered
                okButtonProps={{ disabled: observaciones.trim() === '' }}
            >
                <p>Observación:</p>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextArea
                        placeholder="Escribe aquí..."
                        rows={4}
                        style={{
                            height: 100,
                            resize: 'none',
                        }}
                        value={observaciones}
                        onChange={(e) => setObservaciones(e.target.value)}
                        // onChange={onChangeCancelSolicitud}
                    />
                </div>
            </Modal>
 

            <Row>
                <Col span={24}>
                    <Table size="small"
                           scroll={{
                               x: 2100,
                           }}
                           //bordered={true}
                           pagination={{
                               position: [bottom],
                               pagination: {
                                   current: 1,
                                   pageSize: 10,
                               }}}
                           rowSelection={rowSelection}
                           columns={columns} dataSource={data}
                           onRow={(record) => {
                               return {
                                   onDoubleClick: async () => {
                                       //selectRowUpdate(record);
                                   }
                               }
                           }}/>
                </Col>
            </Row>


         </div>
        </Spin>
    );
};
export default ViajePersonalizado;