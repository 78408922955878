import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import { Button, Table, message, Form, Modal, Select, Spin, Space, Row, Col, Tooltip, Tag, Input } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";

const { Option } = Select;
const EditableContext = React.createContext(null);

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const CerrarViaje = () => {
    const [data, setData] = useState([]);
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando datos...");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    useEffect(() => {
        recargar();
    }, []);

    const recargar = () => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        Axios.get(`ht/listarVariablesCerrarViaje`, { headers: headers })
            .then(response => {
                setSpinning(false);
                if (response.data.data.length > 0) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        aux.push({
                            key: response.data.data[i].idvariablecerrarviaje,
                            idvariablecerrarviaje: response.data.data[i].idvariablecerrarviaje,
                            porcientoalerta: response.data.data[i].porcientoalerta,
                            porcientocierre: response.data.data[i].porcientocierre
                        });
                    }
                }
                setData(aux);
            })
            .catch(error => {
                setSpinning(false);
            });
    };

    const handleSave = (row) => {
        const newData = [...data];
        const index = newData.findIndex(item => row.key === item.key);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setData(newData);
            saveData(newData[index]);
        } else {
            newData.push(row);
            setData(newData);
            saveData(row);
        }
    };

    const saveData = (row) => {
        Axios.post("ht/actualizarVariablesCerrarViaje", {
            idvariablecerrarviaje: row.idvariablecerrarviaje,
            porcientoalerta: row.porcientoalerta,
            porcientocierre: row.porcientocierre
        }, {
            headers: headers
        }).then(response => {
            messageApi.success('Datos actualizados correctamente');
        }).catch(error => {
            messageApi.error('Error al actualizar los datos');
        });
    };

    const columns = [
        {
            title: 'Porciento Alerta',
            dataIndex: 'porcientoalerta',
            // width: 200,
            editable: true,
            render: (text) => (
                <Tag color='green'>
                    {text.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Porciento Cierre',
            dataIndex: 'porcientocierre',
            editable: true,
            render: (text) => (
                <Tag color='red'>
                    {text.toUpperCase()}
                </Tag>
            ),
        },
    ];

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        };
    });

    // const onSelectChange = (newSelectedRowKeys) => {
    //     setSelectedRowKeys(newSelectedRowKeys);
    // };
    //
    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    // };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div>
                <p style={{ textAlign: "center", margin: 0, fontSize: '20px', fontWeight: 'bold' }}>Variables Cerrar Viaje</p>
                {contextHolder}
                <div style={{ marginBottom: 10 }}>
                    {/*<Space direction='horizontal'>*/}
                    {/*    */}
                    {/*</Space>*/}
                </div>
                <Row>
                    <Col span={24}>
                        <Table
                            size="small"
                            bordered
                            // rowSelection={rowSelection}
                            components={{
                                body: {
                                    row: EditableRow,
                                    cell: EditableCell,
                                },
                            }}
                            columns={mergedColumns}
                            dataSource={data}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </div>
        </Spin>
    );
};

export default CerrarViaje;
