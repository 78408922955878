import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Spin, Space, Row, Col} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {MdAddHome} from "react-icons/md";
import { FormattedMessage } from 'react-intl';
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {CarFilled} from '@ant-design/icons';
import {IoReload, IoCheckmarkCircle, IoCloseCircle, IoCheckmarkDone } from "react-icons/io5";
import Search from "antd/es/input/Search";
import _ from "lodash";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

// const { Option } = Select;

const ViajesChofer = () => {

    if(sessionStorage.getItem('viajes_chofer_mod'))
       sessionStorage.removeItem('viajes_chofer_mod');

    const [bottom, setBottom] = useState('bottomCenter');

    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: 'Categoría Servicio',
            dataIndex: 'categoriaservicio',
            filters: _.uniqWith(filterData(data)(i => i.categoriaservicio), _.isEqual),
            onFilter: (value, record) => record.categoriaservicio.startsWith(value),
        },{
            title: 'Fecha Viaje',
            dataIndex: 'fechaviaje',
            filters: _.uniqWith(filterData(data)(i => i.fechaviaje), _.isEqual),
            onFilter: (value, record) => record.fechaviaje.startsWith(value),
        },{
            title: 'Origen',
            dataIndex: 'origen',
            filters: _.uniqWith(filterData(data)(i => i.origen), _.isEqual),
            onFilter: (value, record) => record.origen.startsWith(value),
        },{
            title: 'Destino',
            dataIndex: 'destino',
            filters: _.uniqWith(filterData(data)(i => i.destino), _.isEqual),
            onFilter: (value, record) => record.destino.startsWith(value),
        },{
            title: 'Hora Salida',
            dataIndex: 'horasalida',
            filters: _.uniqWith(filterData(data)(i => i.horasalida), _.isEqual),
            onFilter: (value, record) => record.horasalida.startsWith(value),
        },{
            title: 'Regresa',
            dataIndex: 'regresa',
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Fecha Regreso',
            dataIndex: 'fecharegreso',
            filters: _.uniqWith(filterData(data)(i => i.fecharegreso), _.isEqual),
            onFilter: (value, record) => record.fecharegreso.startsWith(value),
        },{
            title: 'Hora Regreso',
            dataIndex: 'horaregreso',
            filters: _.uniqWith(filterData(data)(i => i.horaregreso), _.isEqual),
            onFilter: (value, record) => record.horaregreso.startsWith(value),
        },{
            title: 'Cant. Personas',
            dataIndex: 'cantidadpersonas',
        },{
            title: 'Cant. Sillas de Ruedas',
            dataIndex: 'cantidadsillasruedas',
        },
    ];

    const [form] = Form.useForm();

    const navigate = useNavigate();

    const [usuarioSession, setUsuarioSession] = useState([]);

    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando datos...");

    const [messageApi, contextHolder] = message.useMessage();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading] = useState(false);

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    useEffect(() => {
        let info_user = JSON.parse(sessionStorage.getItem('info_user'));
        setUsuarioSession(info_user);
        recargar(info_user);
    }, []);  

    const recargar = (info_user) => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        // let start = 0;
        // let limit = 1000;

        if(!info_user.idusuario){
            info_user.idusuario = JSON.parse(sessionStorage.getItem('info_user')).idusuario;
        }

        Axios.post("ht/listarViajesChofer",
            {
                // "start": start,
                // "limit": limit,
                "idusuario": info_user.idusuario
                // "filtros": {
                //     // "origen": ''
                // }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.data.length>0){
                for(let i=0;i<response.data.data.length;i++){
                    aux.push({
                        key:response.data.data[i].idchofer,
                        idchofer:response.data.data[i].idchofer,
                        categoriaservicio:response.data.data[i].categoriaservicio,
                        fechaviaje:response.data.data[i].fechaviaje,
                        horasalida:response.data.data[i].horasalida,
                        origen:response.data.data[i].origen,
                        destino:response.data.data[i].destino,
                        regresa:response.data.data[i].regresa,
                        fecharegreso:response.data.data[i].fecharegreso,
                        horaregreso:response.data.data[i].horaregreso,
                        cantidadpersonas:response.data.data[i].cantidadpersonas,
                        cantsillarueda:response.data.data[i].cantsillarueda,
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        })
            .catch(error => {
                setSpinning(false);
            })
    };

    const getInfoForm = async (id) => {
        for(let i=0;i<data.length;i++){
            if(data[i].idchofer === id){
                return data[i];
            }
        }
        return [];
    };

    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            let aux = [];
            original.forEach(function(option) {
                if (option.fechaviaje.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;



    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
        <div>
            {/*<h2 style={{textAlign:"center"}}>Configurar Ruta Charter</h2>*/}
            <p style={{textAlign:"center", margin:0, fontSize:'20px', fontWeight:'bold'}}>Mis Viajes</p>
            {contextHolder}
            <div
                style={{
                    marginBottom: 10,
                }}
            >
                {contextHolder}

                <Space direction='horizontal'>
                    <Button type="primary" className="button" icon={<IoReload />} onClick={recargar} loading={loading || loading}>
                        Recargar
                    </Button>

                    <Search
                        placeholder="Fecha Viaje"
                        enterButton
                        style={{
                            width: 250,
                        }}
                        size="middle"
                        onSearch={onSearch}
                        onChange={(e) => {
                            filterLocal(e.target.value);
                        }}
                    />
                </Space>
            </div>

            <Row>
                <Col span={24}>
                    <Table size="small"
                            // scroll={{
                            //     x: 2100,
                            // }}
                           bordered={true}
                           pagination={{
                               position: [bottom],
                               pageSize: 10,
                               // pagination: {
                               //     current: 1,
                               //     pageSize: 10,
                               // }
                           }}
                           // rowSelection={rowSelection}
                           columns={columns} dataSource={data}
                           onRow={(record) => {
                               return {
                                   onDoubleClick: async () => {
                                       //selectRowUpdate(record);
                                   }
                               }
                           }}/>
                </Col>
            </Row>

         </div>
        </Spin>
    );
};
export default ViajesChofer;