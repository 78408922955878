import {Form, Input, Radio, Space, Button, message, Spin} from "antd";
import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import GlobalSelect from "../../generales/GlobalSelect";
import { FormattedMessage } from 'react-intl';

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormUsuario = ({accion}) => {

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const validateEmail = (rule, value, callback) => {
        const emailRegex = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
        if (accion!=="pass" && !emailRegex.test(value)) {
          callback('Invalid email address');
        } else {
          callback();
        }
    };  

    const validatePhone = (rule, value, callback) => {
        const phoneRegex = /^(0|[1-9]\d*)$/;
        if (accion !== "pass" && !phoneRegex.test(value)) {
          callback('El teléfono solo debe contener números');
        } else {
          callback();
        }
    }; 

    const [messageApi, contextHolder] = message.useMessage();

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando información del usuario...");

    const [title, setTitle] = useState((accion === "mod")?
    <FormattedMessage id="form_user_title_add" defaultMessage='Modificar Usuario' />:
    <FormattedMessage id="form_user_title_edit" defaultMessage='Adicionar Usuario' />
     );

    const [roles, setRoles] = useState([]);

    const navigate = useNavigate();

    const { id } = useParams();

    const [form] = Form.useForm();

    useEffect(() => {
        cargarRoles();
        if(accion === "mod"){
            if(!sessionStorage.getItem('user_mod')){
                navigate('/usuarios');
            }else{
                let user_mod = JSON.parse(sessionStorage.getItem('user_mod'));
    
                if(id !== user_mod.idusuario)
                   navigate('/usuarios')
        
                form.setFieldsValue({
                    "nombreusuario": user_mod.nombreusuario,
                    "correo": user_mod.correo,
                    "rol": user_mod.idrol,
                    "telefono": user_mod.telefono,
                    "activo":(user_mod.activo=="1")?1:0
                    });
            }
               
        }
    
        if(accion === "pass"){
         if(!sessionStorage.getItem('user_mod')){
            navigate('/usuarios')
         }else{
            let user_mod = JSON.parse(sessionStorage.getItem('user_mod'));
    
            if(id !== user_mod.idusuario)
               navigate('/usuarios')
            
             if(sessionStorage.getItem('user_mod'))
                setTitle("Cambiar contraseña de " + user_mod.correo);
         }
            
        }
    }, []);

    const tailLayout = {
        wrapperCol: { marginTop:10, offset: 8, span: 16 },
      };

    const cargarRoles = () => {
        setSpinTitle("Cargando roles...")
        setSpinning(true);
        let aux = [];
        Axios.get("ht/getRoles",{
            headers: headers
          })
            .then(response => {
                setSpinning(false);
                // console.log(response.data.data);
                if(response.data.data.length>0){
                    for(var i=0;i<response.data.data.length;i++){
                        aux.push({
                            id:response.data.data[i].idrol,
                            nombre:response.data.data[i].denominacion,
                        });
                    }
                }
                setRoles(aux);
            })
            .catch(error => {
                setSpinning(false);
                setRoles(aux);
                console.log("Error",error);
            })
    };

    const cancelar = (values) => {
        navigate('/usuarios');
    };

    const onFinish = (values) => {
        setSpinning(true);
        if(accion === "add"){
            setSpinTitle('Guardando nuevo usuario...');
            Axios.post("ht/addUsser",
                    {
                      "nombreusuario": values.nombreusuario,
                      "correo": values.correo,
                      "contrasenna": values.contrasenna,
                      "idrol": values.rol,
                      "telefono": values.telefono,
                      "activo":values.activo
                    },
                    {
                        headers: headers
                      }
                ).then(response => {
                  setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        navigate('/usuarios');
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
            })
                .catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar el usuario',
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);


                });
        }else if(accion === "pass"){
            setSpinTitle('Cambiando contraseña...');
            Axios.post("ht/changePasswordUsser",
            {
              "idusuario": id,
              "contrasenna": values.contrasenna,
            },
            {
                headers: headers
              }
          ).then(response => {
             setSpinning(false);
             if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
                navigate('/usuarios');
            }else{
                messageApi.open({
                    type: 'error',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
        .catch(error => {
            setSpinning(false);
            messageApi.open({
                type: 'error',
                content: 'Error al guardar el usuario',
                duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);


        });
        }else if(accion === "mod"){
            setSpinTitle('Modificando usuario...');
            Axios.post("ht/updateUsser",
            {
              "idusuario": id,
              "nombreusuario": values.nombreusuario,
              "correo": values.correo,
              "idrol": values.rol,
              "telefono": values.telefono,
              "activo":values.activo
            },
            {
                headers: headers
              }
        ).then(response => {
          setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

                navigate('/usuarios');
            }else{
                messageApi.open({
                    type: 'error',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
        .catch(error => {
            setSpinning(false);
            messageApi.open({
                type: 'error',
                content: 'Error al guardar el usuario',
                duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);


        });
        }

      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
             <div display="flex" align="middle">
            <h2 style={{textAlign:"center"}}>{title}</h2>
            {contextHolder}
             <Form
                form={form}
                autoComplete="on"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 10,
                  }}
                name="form_in_modal"
                initialValues={{
                    activo: 1,
                }}
            >

                 <Form.Item
                     hidden={(accion === "pass")}
                     name="nombreusuario"
                     label={<FormattedMessage id="tabla_usuario_columna_usuario" defaultMessage='Usuario' />}
                     rules={[
                         {
                             required: true,
                             message: 'Por favor debe insertar el Nombre de usuario!',
                         },
                     ]}
                 >
                     <Input />
                 </Form.Item>

                 <Form.Item
                    hidden={(accion === "pass")}
                    name="correo"
                    label={<FormattedMessage id="tabla_usuario_columna_correo" defaultMessage='Correo' />}
                    rules={[
                        {
                            required: (accion !== "pass"),
                            message: 'Por favor debe poner el usuario!',
                        },
                        { validator: validateEmail },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    hidden={(accion === "mod")}
                    name="contrasenna"
                    label={<FormattedMessage id="label_pass" defaultMessage='Contraseña' />}
                    rules={[
                        {
                            required: (accion !== "mod"),
                            message: 'Por favor debe escribir la contraseña!',
                        },
                        {min: 8, message: 'Debe introducir al menos 8 caracteres'}
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    hidden={(accion === "mod")}
                    name="confirm"
                    label={<FormattedMessage id="label_confirm_pass" defaultMessage='Confirmar Contraseña' />}
                    dependencies={['contrasenna']}
                    hasFeedback
                    rules={[
                        {
                            required: (accion !== "mod"),
                            message: 'Por favor confirma la  contraseña!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('contrasenna') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Las contraseñas no coinciden!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    hidden={(accion === "pass")}
                    name="rol"
                    label={<FormattedMessage id="tabla_usuario_columna_rol" defaultMessage='Rol' />}
                    rules={[
                        {
                            required: (accion !== "pass"),
                            message: 'Por favor debe seleccionar el rol!',
                        },
                    ]}>

                    <GlobalSelect loading={spinning} valueName={'rol'} data={roles}/>
                
                </Form.Item>

                <Form.Item
                            hidden={(accion === "pass")}
                            name="telefono"
                            label={<FormattedMessage id="tabla_usuario_columna_telefono" defaultMessage='Teléfono' />}
                            rules={[
                                {
                                    required: (accion !== "pass"),
                                    message: 'Por favor debe poner el teléfono!',
                                },
                                {min: 8, message: 'Debe introducir al menos 8 dígitos'},
                                { validator: validatePhone },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            hidden={(accion === "pass")}
                            // style={{textAlign:"center"}}
                            // label="Opcion"
                            noStyle
                        >
                            <Form.Item
                                // style={{
                                //     display: 'inline-block',
                                //     // width: 'calc(50% - 8px)',
                                // }}
                                noStyle
                                name="activo" >
                                <Radio.Group
                                >
                                    <Radio value={1}>{<FormattedMessage id="modulo_user_btn_activar" defaultMessage="Activar" />} </Radio>
                                    <Radio value={0}>{<FormattedMessage id="modulo_user_btn_desactivar" defaultMessage="Desactivar" />}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Form.Item>
                    

                    <Form.Item {...tailLayout}>
                                <Space>
                                  
                                    <Button danger onClick={cancelar}>
                                        <FormattedMessage id="btn_cancelar" defaultMessage="Cancelar" />
                                      </Button>

                                    <Button type="primary"  htmlType="submit">
                                       <FormattedMessage id="btn_guardar" defaultMessage="Guardar" />
                                    </Button>
                                  
                                 
                                </Space>
                    </Form.Item>
                
            </Form>

        </div>
        </Spin>
       
    );
};

export default FormUsuario;