import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Modal, Select, Spin, Space, Row, Col, Tag, Input} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {IoReload, IoCloseCircle, IoCheckmarkCircle} from "react-icons/io5";
import { ArrowLeftOutlined, CarOutlined } from '@ant-design/icons';
 
import Search from "antd/es/input/Search";
import _ from "lodash";
import dayjs from "../viajepersonalizado/FormViajePersonalizado";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});
const { Option } = Select;

const VerVehiculos = () => {

    const [bottom, setBottom] = useState('bottomCenter');

    const navigate = useNavigate();

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando datos...");

    const [messageApi, contextHolder] = message.useMessage();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [idSolicitudViaje, setIdSolicitudViaje] = useState([]);
    const [idTipoTransporte, setIdTipoTransporte] = useState([]);
    const [idSolicitudOfertasPago, setIdSolicitudOfertasPago] = useState([]);
    const [importeOferta, setImporteOferta] = useState([]);
    const [categoriaServicio, setCategoriaServicio] = useState([]);

    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);
    const [loading/*, setLoading*/] = useState(false);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: 'Tipo Transporte',
            dataIndex: 'tipotransporte',
            width: '20%',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.tipotransporte), _.isEqual),
            onFilter: (value, record) => record.tipotransporte.startsWith(value),
        },{
            title: 'Vehículo',
            dataIndex: 'omnibus',
            width: '20%',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.omnibus), _.isEqual),
            onFilter: (value, record) => record.omnibus.startsWith(value),
        },{
            title: 'Capacidad',
            dataIndex: 'capacidad',
            width: '20%',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.capacidad), _.isEqual),
            onFilter: (value, record) => record.capacidad.startsWith(value),
        },{
            title: 'Características',
            dataIndex: 'caracteristicas',
            render: (tags) => (
                <span>
              {tags.map((tag) => {
                  let color = 'geekblue';
                  return (
                      <Tag color={color} key={tag}>
                          {tag.toUpperCase()}
                      </Tag>
                  );
              })}
            </span>
            ),
        }
    ];

    useEffect(() => {
        let idtipotransporte;
        if(!sessionStorage.getItem('ofertas_mod')){
            navigate('/ofertas');
        }else{
            let user_mod = JSON.parse(sessionStorage.getItem('ofertas_mod'));
            idtipotransporte = user_mod.idtipotransporte;

            setIdSolicitudViaje(user_mod.idsolicitudviaje);
            setIdTipoTransporte(user_mod.idtipotransporte);
            setImporteOferta(user_mod.importeoferta[0]);
            setCategoriaServicio(user_mod.categoriaservicio);
            setIdSolicitudOfertasPago(user_mod.idsolicitudofertaspago);
            // console.log({user_mod});
            // console.log('importeoferta: ' + user_mod.importeoferta[0]);
        }
        recargar(idtipotransporte);
    }, []);  

    const recargar = (idtipotransporte) => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        Axios.post("ht/obtenerVehiculosByTipoTransp",
            {
                "idtipotransporte": idtipotransporte,
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.data.length>0){
                for(let i=0;i<response.data.data.length;i++){
                    aux.push({
                        key:response.data.data[i].idomnibus,
                        idomnibus:response.data.data[i].idomnibus,
                        omnibus:response.data.data[i].omnibus,
                        tipotransporte:response.data.data[i].tipotransporte,
                        capacidad:response.data.data[i].capacidad,
                        caracteristicas:response.data.data[i].caracteristicas.replace(/[ '"]+/g, ' ').split(','),
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        })
            .catch(error => {
                setSpinning(false);
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);


            });
    };

    const asociarVehiculo = (e) => {

        Modal.confirm({
            title: 'Asociar vehiculos!',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea asociar los vehiculos al viaje?</span>
                </div>
            ),
            onOk() {
                setSpinTitle("Procesando datos y generando link de pago...");
                setSpinning(true);

                Axios.post("ht/asociarVehiculo",
                    {
                        "idsolicitudviaje": idSolicitudViaje,
                        "idomnibus": selectedRowKeys
                    },
                    {
                        headers: headers
                    }
                ).then(response => {
                    //setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        //recargar(idTipoTransporte);
                        generarLinkPago();
                    }else{
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al procesar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);

                    });
            },
        });
    };

    const generarLinkPago = (e) => {

        // const selectedRow = data.find(row => row.key === selectedRowKeys[0]);

        Axios.post("ht/createPaymentLink",
            {
                "currency": 'usd',
                "productname": categoriaServicio,
                "amount": importeOferta,
                "quantity": 1,
                "idsolicitudviaje": idSolicitudViaje,
            },
            {
                headers: headers
            }
        ).then(response => {

            if(response.data.success){

                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 3000);

                Axios.post("ht/actualizarRutaPago",
                    {
                        "idsolicitudofertaspago": idSolicitudOfertasPago,
                        "sessionId": response.data.sessionId,
                        "rutapago": response.data.paymentLink
                    },
                    {
                        headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        //recargar(idTipoTransporte);

                        switch(categoriaServicio) {
                            case 'Personalizado':
                                navigate('/listarviajespersonalizadosdispatcher');
                                break;
                            case 'Corporativo':
                                navigate('/listarviajescorporativosdispatcher');
                                break;
                            default:
                                navigate('/vervehiculos');
                        }

                    }
                })

            }else{
                setSpinning(false);
                console.log('Error al procesar los datos');
            }
        }).catch(error => {
            setSpinning(false);
            console.log('Error al procesar los datos');
        });

        // Modal.confirm({
        //     title: 'Generar link de Pago',
        //     cancelText:'Cancelar',
        //     okText:'Aceptar',
        //     okType:'primary',
        //     content: (
        //         <div>
        //             <span >¿Desea generar in link de pago para el viaje seleccionado?</span>
        //         </div>
        //     ),
        //     onOk() {
        //         setSpinTitle("Procesando datos...");
        //         setSpinning(true);
        //         Axios.post("ht/createPaymentLink",
        //             {
        //                 "currency": 'usd',
        //                 "productname": 'Viaje Personalizado',
        //                 "amount": importeOferta,
        //                 "quantity": 1,
        //             },
        //             {
        //                 headers: headers
        //             }
        //         ).then(response => {
        //             setSpinning(false);
        //             if(response.data.success){
        //
        //                 messageApi.open({
        //                     type: 'success',
        //                     content: response.data.message,
        //                     duration: 0,
        //                 });
        //                 setTimeout(messageApi.destroy, 2500);
        //                 recargar(idTipoTransporte);
        //
        //                 Axios.post("ht/actualizarRutaPago",
        //                     {
        //                         "idsolicitudofertaspago": idSolicitudOfertasPago,
        //                         "sessionId": response.data.sessionId,
        //                         "rutapago": response.data.paymentLink
        //                     },
        //                     {
        //                         headers: headers
        //                     }
        //                 ).then(response => {
        //                     setSpinning(false);
        //                     // if(response.data.success){
        //                     // }else{
        //                     // }
        //                 })
        //
        //             }else{
        //                 messageApi.open({
        //                     type: 'error',
        //                     content: 'Error al procesar los datos',
        //                     duration: 0,
        //                 });
        //                 setTimeout(messageApi.destroy, 2500);
        //             }
        //         }).catch(error => {
        //             setSpinning(false);
        //             messageApi.open({
        //                 type: 'error',
        //                 content: 'Error al procesar los datos',
        //                 duration: 0,
        //             });
        //             setTimeout(messageApi.destroy, 2500);
        //         });
        //
        //     },
        // });
    };


    return (

        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
        <div >
            <p style={{textAlign:"center", margin:10, fontSize:'20px', fontWeight:'bold'}}>Listado de Vehículos</p>
            {contextHolder}
            <div
                style={{
                    marginBottom: 2,
                }}
            >
                {contextHolder}
                <Space direction='horizontal'>
                    {/*<Link to="/ofertas">*/}
                    {/*    <Button type="primary" className="button" icon={<ArrowLeftOutlined />} loading={loading || loading}>*/}
                    {/*        /!* *!/*/}
                    {/*    </Button>*/}
                    {/*</Link>*/}
                    <Button style={{marginRight:"0px", marginLeft:"10px"}} type="primary"  className="button" icon={<CarOutlined />} loading={loading || loading} onClick={asociarVehiculo}>
                        Seleccionar Vehiculo
                    </Button>


                </Space>
            </div>

            <Row>
                <Col span={24}>
                    <Table size="small"
                          scroll={{
                              y: 300,
                          }}
                        pagination={{
                            position: [bottom],
                            pagination: {                                
                                current: 1,
                                pageSize: 10,
                            }}}
                            bordered={true}
                           rowSelection={rowSelection}
                           columns={columns} dataSource={data}
                           onRow={(record) => {
                               return {
                                   onDoubleClick: async () => {
                                       // selectRowUpdate(record);
                                   }
                               }
                           }}/>
                </Col>
            </Row>

         </div>
        </Spin>
    );
};
export default VerVehiculos;