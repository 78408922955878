import React, { useState } from 'react';
import { Button, message, Steps, theme, Form, Spin} from 'antd';
import StepSolicViajeCharter from './StepSolicViajeCharter';
import { FormattedMessage } from 'react-intl';
import {useNavigate, useParams } from "react-router-dom";
import StepCantDisponibles from './StepCantDisponibles';
import StepReservarPasajeros from './StepReservarPasajeros';

import axios from 'axios';
export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const StepsSolicViajeCharter = () => {

    const [disabled, setDisabled] = useState(true);

    const [messageApi, contextHolder] = message.useMessage();

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando información ...");

    
  const [cantadultos, setCantAdultos] = useState(0);

  const [cantninnos, setCantNinnos] = useState(0);

  const [cantsillaruedas, setCantSillaruedas] = useState(0);

  const [total, setTotal] = useState(0);

    const headers = {
      'accept': '*/*',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      'Content-Type': 'application/json',
    };

    const steps = [
        {
          title: 'Seleccionar viaje charter',
          content: <StepSolicViajeCharter setDisabled={setDisabled} />,
        },
        {
          title: 'Cantidad disponible',
          content: <StepCantDisponibles setDisabled={setDisabled} />,
        },
        {
          title: 'Reservar pasajeros',
          content: <StepReservarPasajeros setDisabled={setDisabled} />,
        },
      ];

  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    if(current==0){

    }else if(current==1){

    }

    setCurrent(current + 1);
  };
  const reservar = () => {

    let data_pasajeros = JSON.parse(sessionStorage.getItem('data_pasajeros'));

    if(data_pasajeros == null){
      messageApi.open({
        type: 'error',
        content: 'Debe llenar el formulario',
        duration: 0,
      });
      setTimeout(messageApi.destroy, 2500);
    }else{
      setSpinning(true);

      let infor_user = (sessionStorage.getItem('info_user'))? JSON.parse(sessionStorage.getItem('info_user')):null;
      console.log('infor_user', infor_user);
  
      setCantAdultos(parseInt(sessionStorage.getItem('cantadultos')));
      setCantNinnos(parseInt(sessionStorage.getItem('cantninnos')));
      setCantSillaruedas(parseInt(sessionStorage.getItem('cantsillaruedas')));
      setTotal(parseInt(sessionStorage.getItem('cantadultos')) +
                 parseInt(sessionStorage.getItem('cantninnos')));
  
      let ruta_seleccionada = JSON.parse(sessionStorage.getItem('ruta'));
      //console.log('ruta_seleccionada', ruta_seleccionada);
  
  
      Axios.post("ht/solicitarViaje",
      {
          "idusuariocrea":infor_user.idusuario,
          "idcategoriaservicio": 3,
          "idviajecharterfecha": ruta_seleccionada.idviajecharterfecha,
          "origen": ruta_seleccionada.origen,
          "destino": ruta_seleccionada.destino,
          "fechaviaje": ruta_seleccionada.fechaviaje,
          "horasalida": ruta_seleccionada.horasalida,
          "correoregistro": infor_user.correo,
          "telefonousuariosolic": infor_user.telefono,
          "idtipotransporte": ruta_seleccionada.idtipotransporte,
          "cantninnos": cantninnos,
          "cantadultos": cantadultos,
          "cantsillaruedas": cantsillaruedas,
          "regresa": ruta_seleccionada.regresa,
          "precioruta": ruta_seleccionada.precioruta,
          "preciorutaninno": ruta_seleccionada.preciorutaninno,
          "fecharegreso": "",
          "horaregreso": ""
        },
        {
            headers: headers
        }
      ).then(response => {
           if(response.data.success){
             let idsolicitudviaje = response.data.data[0].idsolicitudviaje;
             addPasajerosViaje(idsolicitudviaje);
           } else{
              setSpinning(false);
              messageApi.open({
                type: 'error',
                content: 'Error al guardar la información',
                duration: 0,
              });
              setTimeout(messageApi.destroy, 2500);
           }
      })
          .catch(error => {
            setSpinning(false);
            messageApi.open({
              type: 'error',
              content: 'Error al guardar la información',
              duration: 0,
            });
            setTimeout(messageApi.destroy, 2500);
          });
    }
  };

  const addPasajerosViaje = (idsolicitudviaje) => {
    let data_pasajeros = JSON.parse(sessionStorage.getItem('data_pasajeros'));
      let info_user = JSON.parse(sessionStorage.getItem('info_user'));

    let pasajeros = [];

    for(let i=0;i<data_pasajeros.length;i++){
      pasajeros.push({
        "idviajevehiculo": data_pasajeros[i].idviajevehiculo,
        "idsolicitudviaje": idsolicitudviaje,
        "nombre": data_pasajeros[i].nombre,
        "edad": data_pasajeros[i].edad,
        "ninno": (data_pasajeros[i].ninno == undefined)?0:(data_pasajeros[i].ninno)?1:0,
        "sillarueda": (data_pasajeros[i].sillarueda == undefined)?0:(data_pasajeros[i].sillarueda)?1:0,
      });
    }

    Axios.post("ht/addPasajerosViaje",
       {
         "idusuariocreaoferta": info_user.idusuario,
         "pasajeros": pasajeros
       },
        {
            headers: headers
        }
    ).then(response => {
         setSpinning(false);
         if(response.data.success){
          messageApi.open({
            type: 'success',
            content: 'Se ha reservado su viaje.',
            duration: 0,
          });
          setTimeout(messageApi.destroy, 2500);
          navigate('/solicviajecharter');
         } 
         else {
          messageApi.open({
            type: 'error',
            content: 'Error al guardar la información',
            duration: 0,
          });
          setTimeout(messageApi.destroy, 2500);
         }
    })
        .catch(error => {
          setSpinning(false);
          messageApi.open({
            type: 'error',
            content: 'Error al guardar la información',
            duration: 0,
          });
          setTimeout(messageApi.destroy, 2500);
        });
  };

  const cancelar = () => {
    navigate('/solicviajecharter');
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  return (
    <Spin spinning={spinning} tip={spinTitle} size={"large"}>
    <div style={{margin:10}}>
      {contextHolder}
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div  >
        {/*current > 0 && */(
          <Button
            style={{
              margin: '0 8px',
            }}
            danger
            onClick={cancelar}
          >
            <FormattedMessage id="btn_cancelar" defaultMessage='Cancelar' />
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" disabled={disabled} onClick={() => next()}>
            <FormattedMessage id="btn_siguiente" defaultMessage='Siguiente' />
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" disabled={disabled} onClick={() => reservar()}>
            <FormattedMessage id="btn_reservar" defaultMessage='Reservar' />
          </Button>
        )}
      </div>
    </div>
    </Spin>
  );
};
export default StepsSolicViajeCharter;