import {Checkbox, Form, Input, Space, Button, message, Spin, InputNumber, TimePicker, Row, Col, DatePicker} from "antd";
import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import GlobalSelect from "../../generales/GlobalSelect";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import moment from 'moment';

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormConfRutaCharter = ({accion}) => {

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando datos...");

    const [title] = useState((accion === "mod")?"Modificar Ruta Charter":"Adicionar Ruta Charter");

    const [tipoTransporte, setTipoTransporte] = useState([]);

    const [horaSalida, setHoraSalida] = useState([]);

    const [horaLlegada, setHoraLlegada] = useState([]);

    const [flagHoraSalida, setFlagHoraSalida] = useState(false);

    const [flagHoraLlegada, setFlagHoraLlegada] = useState(false);

    const [idRutaCharter, setidRutaCharter] = useState([]);

    const navigate = useNavigate();

    const { id } = useParams();

    const [form] = Form.useForm();

    dayjs.extend(customParseFormat);
    const onTimePickerSalidaChange = (time, timeString) => {
        setHoraSalida(timeString);
        setFlagHoraSalida(true);
    };
    const onTimePickerLlegadaChange = (time, timeString) => {
        setHoraLlegada(timeString);
        setFlagHoraLlegada(true);
    };

    useEffect(() => {
        if(accion === "mod"){
            if(!sessionStorage.getItem('user_mod')){
                navigate('/confrutacharter');
            }else{
                let user_mod = JSON.parse(sessionStorage.getItem('user_mod'));
                // console.log('sessionStorage : ' , user_mod);

                setidRutaCharter(user_mod.idrutacharter);
                setHoraSalida(user_mod.horasalida);
                setHoraLlegada(user_mod.horallegada);
 

                if(id !== user_mod.idrutacharter)
                   navigate('/confrutacharter')

                form.setFieldsValue({
                    "idrutacharter": idRutaCharter,
                    "nombreruta": user_mod.nombreruta,
                    "idtipotransporte": user_mod.idtipotransporte,
                    "origen": user_mod.origen,
                    "destino": user_mod.destino,
                    "horasalida": dayjs(user_mod.horasalida, 'HH:mm'),
                    "horallegada": dayjs(user_mod.horallegada, 'HH:mm'),
                    "precioruta": user_mod.precioruta,
                    "preciorutaninno": user_mod.preciorutaninno,
                    "regresa":(user_mod.regresa === "1"),
                    });
            }
        }

    }, []);

    const tailLayout = {
        wrapperCol: { marginTop:10, offset: 8, span: 16 },
      };

    const getTipoTransporte = () => {
        setLoading(true);
        let aux = [];
        let start = 0;
        let limit = 1000;
        Axios.get(`ht/getTipoTransporte?start=${start}&limit=${limit}`,{
            headers: headers
          })
            .then(response => {
                setLoading(false);
                // console.log(response.data.data);
                if(response.data.data.length>0){
                    for(let i=0;i<response.data.data.length;i++){
                        aux.push({
                            id: response.data.data[i].idtipotransporte,
                            nombre: response.data.data[i].denominacion,
                        });
                    }
                }
                setTipoTransporte(aux);
            })
            .catch(error => {
                setLoading(false);
                setTipoTransporte(aux);
                console.log("Error",error);
            })
    };

    useEffect(() => {
        getTipoTransporte();
    }, []);

    const cancelar = (values) => {
        navigate('/confrutacharter');
    };

    const onFinish = (values) => {
        setSpinning(true);
        if(accion === "add"){

            console.log('ADDD datos...', values);
            setSpinTitle('Guardando datos...');
            Axios.post("ht/addRutaCharter",
                    {
                      "nombreruta": values.nombreruta,
                      "idtipotransporte": values.idtipotransporte,
                      "idusuarioregistra": sessionStorage.getItem('idusuario'),
                      "origen": values.origen,
                      "destino": values.destino,
                      "horasalida": horaSalida,
                      "horallegada": horaLlegada,
                      "precioruta": values.precioruta,
                      "preciorutaninno": values.preciorutaninno,
                      "regresa": (values.regresa)?1:0,
                    },
                    {
                        headers: headers
                      }
                ).then(response => {
                  setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        navigate('/confrutacharter');
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al guardar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
            })
                .catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar los datos',
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);


                });
        }else if(accion === "mod"){
            setSpinTitle('Modificando datos...');
            Axios.post("ht/updateRutaCharter",
            {
                "idrutacharter": idRutaCharter,
                "nombreruta": values.nombreruta,
                "idtipotransporte": values.idtipotransporte,
                "idusuarioregistra": sessionStorage.getItem('idusuario'),
                "origen": values.origen,
                "destino": values.destino,
                "horasalida": horaSalida,
                "horallegada": horaLlegada,
                // "horasalida": (flagHoraSalida === true)?dayjs(values.horasalida, 'HH:mm'):horaSalida,
                // "horallegada": (flagHoraLlegada === true)?dayjs(values.horallegada, 'HH:mm'):horaLlegada,
                "precioruta": values.precioruta,
                "preciorutaninno": values.preciorutaninno,
                "regresa": (values.regresa)?1:0,
            },
            {
                headers: headers
              }
        ).then(response => {
          setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

                navigate('/confrutacharter');
            }else{
                messageApi.open({
                    type: 'error',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
        .catch(error => {
            setSpinning(false);
            messageApi.open({
                type: 'error',
                content: 'Error al guardar los datos',
                duration: 0,
            });
            setTimeout(messageApi.destroy, 2500);
        });
        }

      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };



    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
             <div display="flex" align="middle">
            <h2 style={{textAlign:"center"}}>{title}</h2>
            {/*<p style={{textAlign:"center", margin:0, fontSize:'20px', fontWeight:'bold'}}>{title}</p>*/}
            {contextHolder}
                 <Form
                     form={form}
                     name="three_column_form"
                     labelCol={{ span: 12 }}
                     wrapperCol={{ span: 16 }}
                     onFinish={onFinish}
                 >
                     <Row gutter={24}>
                         <Col span={11}>
                             {/*columna 1*/}
                             <Form.Item
                                 name="nombreruta"
                                 label="Nombre Ruta"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner el nombre!',
                                     },
                                 ]}
                             >
                                 <Input />
                             </Form.Item>

                             <Form.Item
                                 name="origen"
                                 label="Origen"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner el Origen!',
                                     },
                                 ]}
                             >
                                 <Input />
                             </Form.Item>

                             <Form.Item
                                 name="precioruta"
                                 label="Precio Adulto"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner el Precio Adulto!',
                                     },
                                 ]}
                             >
                                 <InputNumber
                                     addonBefore="$"
                                     // prefix="$"
                                     // addonAfter="$"
                                     // defaultValue={100}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="horasalida"
                                 label="Hora Salida"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner la Hora Salida!',
                                     },
                                 ]}
                             >
                                 <TimePicker
                                     onChange={onTimePickerSalidaChange}
                                     placeholder="Seleccione Hora Salida"
                                     //open={horaSalida}
                                     // onOpenChange={onTimePickerSalidaChange}
                                     format={'HH:mm'}
                                     defaultOpenValue={dayjs('00:00', 'HH:mm')}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                         </Col>
                         <Col span={11} >
                             {/*columna 2*/}
                             <Form.Item
                                 name="idtipotransporte"
                                 label="Tipo Transporte"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe seleccionar el Tipo Transporte!',
                                     },
                                 ]}>

                                 <GlobalSelect loading={loading} valueName={'idtipotransporte'} data={tipoTransporte}/>

                             </Form.Item>

                             <Form.Item
                                 name="destino"
                                 label="Destino"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner el Destino!',
                                     },
                                 ]}
                             >
                                 <Input />
                             </Form.Item>

                             <Form.Item
                                 name="preciorutaninno"
                                 label="Precio Niño"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner el Precio Niño!',
                                     },
                                 ]}
                             >
                                 <InputNumber
                                     addonBefore="$"
                                     // prefix="$"
                                     // addonAfter="$"
                                     // defaultValue={100}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="horallegada"
                                 label="Hora Llegada"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner la Hora Llegada!',
                                     },
                                 ]}
                             >
                                 <TimePicker
                                     placeholder="Seleccione Hora Llegada"
                                     onChange={onTimePickerLlegadaChange}
                                     // onOpenChange={onTimePickerLlegadaChange}
                                     format={'HH:mm'}
                                     defaultOpenValue={dayjs('00:00:00', 'HH:mm')}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>



                         </Col>
                         {/*<Col span={8} >*/}
                         {/*    /!*columna 3*!/*/}



                         {/*</Col>*/}
                     </Row>

                     <Row gutter={24}>
                         <Col span={24}>

                             <Form.Item
                                 name="regresa"
                                 label="Regresa"
                                 valuePropName="checked"
                                 initialValue={true}
                                 noStyle
                             >
                                 <Checkbox defaultChecked={true}>Regresa</Checkbox>
                             </Form.Item>

                         </Col>
                     </Row>

                     <Form.Item wrapperCol={{ offset: 12, span: 16 }} {...tailLayout}>
                         <Space>

                             <Button danger onClick={cancelar}>
                                 Cancelar
                             </Button>
                             <Button type="primary"  htmlType="submit">
                                 Guardar
                             </Button>

                         </Space>
                     </Form.Item>
                 </Form>

        </div>
        </Spin>

    );
};

export default FormConfRutaCharter;