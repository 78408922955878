import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Spin, Space, Row, Col, Tooltip, Tag} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {MdAddHome} from "react-icons/md";
import { FormattedMessage } from 'react-intl';
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {CarFilled, LinkOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {IoReload, IoCheckmarkCircle, IoCloseCircle, IoCheckmarkDone } from "react-icons/io5";
import Search from "antd/es/input/Search";
import _ from "lodash";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

// const { Option } = Select;

const ConfRutaCharter = () => {

    if(sessionStorage.getItem('viaje_charter_mod'))
       sessionStorage.removeItem('viaje_charter_mod');

    const [bottom, setBottom] = useState('bottomCenter');

    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: 'Fecha Viaje',
            dataIndex: 'fechaviaje',
            width: 130,
            filters: _.uniqWith(filterData(data)(i => i.fechaviaje), _.isEqual),
            onFilter: (value, record) => record.fechaviaje.startsWith(value),
        },{
            title: 'Nombre Ruta',
            dataIndex: 'nombreruta',
            width: 130,
            filters: _.uniqWith(filterData(data)(i => i.nombreruta), _.isEqual),
            onFilter: (value, record) => record.nombreruta.startsWith(value),
        
        },{
            title: 'Origen',
            dataIndex: 'origen',
            width: 130,
            filters: _.uniqWith(filterData(data)(i => i.origen), _.isEqual),
            onFilter: (value, record) => record.origen.startsWith(value),
        },{
            title: 'Destino',
            dataIndex: 'destino',
            width: 130,
            filters: _.uniqWith(filterData(data)(i => i.destino), _.isEqual),
            onFilter: (value, record) => record.destino.startsWith(value),
        },{
            title: 'Hora Salida',
            dataIndex: 'horasalida',
            width: 130,
            filters: _.uniqWith(filterData(data)(i => i.horasalida), _.isEqual),
            onFilter: (value, record) => record.horasalida.startsWith(value),
        },{
            title: 'Hora Llegada',
            dataIndex: 'horallegada',
            width: 130,
            filters: _.uniqWith(filterData(data)(i => i.horallegada), _.isEqual),
            onFilter: (value, record) => record.horallegada.startsWith(value),
        },{
            title: 'Precio Adulto',
            dataIndex: 'precioruta',
            width: 130,
        },{
            title: 'Precio Niño',
            dataIndex: 'preciorutaninno',
            width: 130,
        },{
            title: 'Vehículos',
            dataIndex: 'idomnibus',
            width: 130,
            render: (text) => (text === null)?<IoCloseCircle size={20} color={'red'}/>:<IoCheckmarkCircle size={20} color={'green'}/>,
        },{
            title: 'Regresa',
            dataIndex: 'regresa',
            width: 130,
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Activo',
            dataIndex: 'activa',
            width: 130,
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Sobreventa',
            dataIndex: 'sobreventa',
            width: 130,
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Silla de ruedas',
            dataIndex: 'sillaruedas',
            width: 150,
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Estado Viaje',
            dataIndex: 'estadoviaje',
            // fixed: 'left',
            width: 130,
            render: (tags) => (
                <span>
              {tags.map((tag) => {
                  let color;
                  switch (tag) {
                      case 'Cerrado automático':
                          color = '#ff2500';
                          break;
                      case 'Cerrado Dispatcher':
                          color = '#9E4624';
                          break;
                      default:
                          color = '#ffffff';
                  }
                  return (
                      <Tag color={color} key={tag}>
                          {tag.toUpperCase()}
                      </Tag>
                  );
              })}
            </span>
            ),

        },{
            title: '% despacho',
            dataIndex: 'porcientodespacho',
            width: 150,
        },
        {
            title: 'Cant. Solicitudes',
            dataIndex: 'cantidadsolicitudes',
            width: 230,
        },
        {
            title: 'Cant. Personas',
            dataIndex: 'cantidadpersonas',
            width: 230,
        },
        {
            title: 'Cant. Adultos',
            dataIndex: 'cantidadadultos',
            width: 190,
        },
        {
            title: 'Cant. Niños',
            dataIndex: 'cantidadninos',
            width: 190,
        },
        {
            title: 'Cant. Sillas de Ruedas',
            dataIndex: 'cantidadsillasruedas',
            width: 380,
        },
    ];

    const [form] = Form.useForm();

    const navigate = useNavigate();

    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando datos...");

    const [messageApi, contextHolder] = message.useMessage();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading] = useState(false);

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    useEffect(() => {
        recargar();
    }, []);  

    const recargar = () => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        let start = 0;
        let limit = 1000;

        Axios.post("ht/getViajeCharterFecha",
            {
                "start": start,
                "limit": limit,
                "filtros": {
                    // "origen": ''
                }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.data.length>0){
                for(let i=0;i<response.data.data.length;i++){
                    aux.push({
                        key:response.data.data[i].idviajecharterfecha,
                        idviajecharterfecha:response.data.data[i].idviajecharterfecha,
                        idviajecharter:response.data.data[i].idviajecharter,
                        nombreruta:response.data.data[i].nombreruta,
                        origen:response.data.data[i].origen,
                        destino:response.data.data[i].destino,
                        horasalida:response.data.data[i].horasalida,
                        horallegada:response.data.data[i].horallegada,
                        precioruta:response.data.data[i].precioruta,
                        preciorutaninno:response.data.data[i].preciorutaninno,
                        idomnibus:response.data.data[i].idomnibus,
                        regresa:response.data.data[i].regresa,
                        activa:response.data.data[i].activa,
                        sillaruedas:response.data.data[i].sillaruedas,
                        sobreventa:response.data.data[i].sobreventa,
                        idtipotransporte:response.data.data[i].idtipotransporte,
                        fechaviaje:response.data.data[i].fechaviaje,
                        porcientodespacho:response.data.data[i].porcientodespacho,
                        cantidadsolicitudes:response.data.data[i].cantidadsolicitudes,
                        cantidadpersonas:response.data.data[i].cantidadpersonas,
                        cantidadadultos:response.data.data[i].cantidadadultos,
                        cantidadninos:response.data.data[i].cantidadninos,
                        cantidadsillasruedas:response.data.data[i].cantidadsillasruedas,
                        estadoviaje:[response.data.data[i].estadoviaje],
                        viajecerrado:response.data.data[i].viajecerrado,
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        })
            .catch(error => {
                setSpinning(false);
            })
    };

    const getInfoForm = async (id) => {
        for(let i=0;i<data.length;i++){
            if(data[i].idviajecharterfecha === id){
                return data[i];
            }
        }
        return [];
    };

    const modificar = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        sessionStorage.setItem('viaje_charter_mod', JSON.stringify(row));
    };

    const eliminar = (e) => {
        Modal.confirm({
            title: 'Eliminar datos!',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea eliminar los datos seleccionados?</span>
                </div>
            ),
            onOk() {
                setSpinTitle("Eliminando datos...");
                setSpinning(true);
                Axios.delete('ht/deleteViajeCharterFecha',
                    {
                      data: {
                        "idviajecharterfecha": selectedRowKeys
                      },
                      headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);

                    });
            },
        });
    };

    function  activacion(e, activar){

        Modal.confirm({
            title: (activar)?'Activar viaje':'Desactivar viaje',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea {(activar)?'activar':'desactivar'} los viajes seleccionado?</span>
                </div>
            ),
            onOk() {
                setSpinTitle((activar)?'Activando viajes...':'Desactivando viajes...');
                setSpinning(true);
                Axios.post("ht/activarDesactivarViajeCharterFecha",
                     {
                            idviajecharterfecha:selectedRowKeys,
                            activa:(activar)?1:0
                    },
                    {
                        headers: headers
                      }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: (activar)?'Viajes activado satisfactoriamente':'Viajes desactivado satisfactoriamente',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: (activar)?'Error activando viajes':'Error desactivando viajes',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: (activar)?'Error activando viajes':'Error desactivando viajes',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);


                    });
            },
        });
    }


    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            var aux = [];
            original.forEach(function(option) {
                if (option.fechaviaje.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };


    const [cerrarViajeButtonDisabled, setCerrarViajeButtonDisabled] = useState(true);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        if (newSelectedRowKeys.length === 1) {
            const selectedRow = data.find(row => row.key === newSelectedRowKeys[0]);
            if (selectedRow) {
                setCerrarViajeButtonDisabled(selectedRow.viajecerrado != 0);
                
            }
        }else{
            setCerrarViajeButtonDisabled(true);
        }
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const selectRowUpdate  = async (record) => {
        setSelectedRowKeys([record.key]);
        form.resetFields();
        let row = [];
        for(let i=0;i<data.length;i++){
            if(data[i].idviajecharterfecha === record.key){
                row = data[i];
            }
        }

         //console.log('ROW : ', row);
       
        sessionStorage.setItem('viaje_charter_mod', JSON.stringify(row));
        navigate(`/editviajecharter/${record.key}`)
        
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const cerrarviaje = (e) => {
        Modal.confirm({
            title: 'Cerrar viaje!',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea cerrar los viajes seleccionados?</span>
                </div>
            ),
            onOk() {
                setSpinTitle("Procesando datos...");
                setSpinning(true);
                Axios.post("ht/cerrarViajeDispatcher",
                    {
                        "idviajecharterfecha": selectedRowKeys
                    },
                    {
                        headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al procesar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al procesar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);

                    });
            },
        });
    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
        <div>
            {/*<h2 style={{textAlign:"center"}}>Configurar Ruta Charter</h2>*/}
            <p style={{textAlign:"center", margin:0, fontSize:'20px', fontWeight:'bold'}}>Viaje Charter</p>
            {contextHolder}
            <div
                style={{
                    marginBottom: 10,
                }}
            >
                {contextHolder}

                <Space direction='horizontal'>
                    <Link to="/addviajecharter">
                        <Tooltip title="Planificar Viaje">
                            <Button style={{marginRight:"0px", marginLeft:"10px"}} type="primary" shape="circle" className="button" icon={<MdAddHome />} disabled={loading}>
                                {/* Planificar Viaje */}
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to={`/editviajecharter/${selectedRowKeys[0]}`}>
                        <Tooltip title="Modificar">
                            <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" className="button" shape="circle" icon={<AiFillEdit />} onClick={modificar} disabled={loading || selectedRowKeys.length !== 1}>
                                {/*Modificar*/}
                            </Button>
                        </Tooltip>
                    </Link>
                    <Tooltip title="Eliminar">
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" danger className="button" icon={<AiFillDelete />} onClick={eliminar}
                                disabled={!hasSelected || loading}>
                            {/*Eliminar*/}
                        </Button>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="btn_activar" defaultMessage="Activar" />}>
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" className="button" icon={<IoCheckmarkCircle />} onClick={(e) => {activacion(e, true);}} disabled={!hasSelected || loading}>

                        </Button>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="btn_desactivar" defaultMessage="Desactivar" />}>
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" danger className="button" icon={<IoCloseCircle />} onClick={(e) => {activacion(e, false);}} disabled={!hasSelected || loading}>

                        </Button>
                    </Tooltip>
                    <Tooltip title="Cerrar Viaje">
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle"  className="button" icon={<CloseCircleOutlined />} onClick={cerrarviaje}
                                disabled={cerrarViajeButtonDisabled}>
                            {/*Cerrar Viaje*/}
                        </Button>
                    </Tooltip>
                    <Tooltip title="Recargar">
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" className="button" icon={<IoReload />} onClick={recargar} loading={loading || loading}>
                            {/*Recargar*/}
                        </Button>
                    </Tooltip>

                    <Search
                        placeholder="Fecha Viaje"
                        enterButton
                        style={{
                            width: 200,
                        }}
                        size="middle"
                        onSearch={onSearch}
                        onChange={(e) => {
                            filterLocal(e.target.value);
                        }}
                    />
                </Space>
            </div>
 

            <Row>
                <Col span={24}>
                    <Table size="small"
                            scroll={{
                                x: 2100,
                            }}
                           bordered={true}
                           pagination={{
                               position: [bottom],
                               pagination: {
                                   current: 1,
                                   pageSize: 10,
                               }}}
                           rowSelection={rowSelection}
                           columns={columns} dataSource={data}
                           onRow={(record) => {
                               return {
                                   onDoubleClick: async () => {
                                       selectRowUpdate(record);
                                   }
                               }
                           }}/>
                </Col>
            </Row>

         </div>
        </Spin>
    );
};
export default ConfRutaCharter;