import {createContext, useState, useEffect} from 'react'
import axios from 'axios'

export const LoginContext = createContext();

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

export const LoginContextProvider = ({children}) => {

    const [wait, setWait] = useState(false);

    const [token, setToken] = useState(null);

    const [headers, setHeaders] = useState(null);

    useEffect(() => {
       async function getToken() {
        setWait(true);
        Axios.post('/login',{
            "username": process.env.REACT_APP_USER,
            "password": process.env.REACT_APP_PASS
          }).then(response =>{
            setWait(false);
            let aux = response.data.token;
            setToken(aux);
            let headerAux = {
                'accept': '*/*',
                'Authorization': 'Bearer ' + response.data.token,
                'Content-Type': 'application/json',
              };
              setHeaders(headerAux);
          }).catch(error => {
            setWait(false)
            setToken(null);
            setHeaders(null);
          });   
       }
       getToken();
    }, [])

    const autenticar = async ({username,password}) => {
        setWait(true);

        console.log('Mi token', token);

        console.log('Mi header', headers);

        Axios.post('ht/login',
                    {
                    "correo": username,
                    "contrasenna": password
                    },
                    {
                        headers: headers
                      }
        ).then(response =>{
            console.log('response', response);
        setWait(false);
        if(response.data.success){
            sessionStorage.setItem('idusuario', 4);
            /*sessionStorage.setItem('usuario', data.data[0].username);*/
            setWait(false);
        }

        return response.data;
           
           
        }).catch(error => {
           sessionStorage.removeItem('idusuario');
           sessionStorage.removeItem('info_user');
           setWait(false);
           return {success:false, message:"Error de conexión con el servidor."};
        });  

    }

    
    const logout = () => {
        sessionStorage.removeItem('idusuario');
        sessionStorage.removeItem('info_user');
        window.location='/'
    }

    return (
        <LoginContext.Provider value={{autenticar,wait, logout}}>
            {children}
        </LoginContext.Provider>
    );

}

export default LoginContextProvider;