import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Modal, Select, Spin, Space, Row, Col, Tag, Input} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {IoReload, IoCloseCircle, IoCheckmarkCircle} from "react-icons/io5";
import { ArrowLeftOutlined } from '@ant-design/icons';
 
import Search from "antd/es/input/Search";
import _ from "lodash";
import dayjs from "../viajepersonalizado/FormViajePersonalizado";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});
const { Option } = Select;

const HistorialOfertas = () => {

    const [bottom, setBottom] = useState('bottomCenter');

    const navigate = useNavigate();

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando usuarios...");

    const [messageApi, contextHolder] = message.useMessage();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);
    const [loading/*, setLoading*/] = useState(false);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: 'Actual',
            dataIndex: 'ofertaactual',
            width: '5%',
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Fecha Registro',
            dataIndex: 'fecharegistro',
            width: '10%',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.fecharegistro), _.isEqual),
            onFilter: (value, record) => record.fecharegistro.startsWith(value),
        },{
            title: 'Estado Oferta',
            dataIndex: 'estadooferta',
            width: '10%',
            render: (tags) => (
                <span>
              {tags.map((tag) => {
                  let color;
                  switch (tag) {
                      case 'Aceptada':
                          color = '#51cf66';
                          break;
                      case 'Ofertada':
                          color = '#5c7cfa';
                          break;
                      case 'Rechazada':
                          color = '#ff6b6b';
                          break;
                      default:
                          color = '#ff8c00';
                  }
                  return (
                      <Tag color={color} key={tag}>
                          {tag.toUpperCase()}
                      </Tag>
                  );
              })}
            </span>
            ),
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.estadooferta), _.isEqual),
            onFilter: (value, record) => record.estadooferta.startsWith(value)
        },{
            title: 'Importe',
            dataIndex: 'importeoferta',
            width: '5%',
            render: (tags) => (
                <span>
              {tags.map((tag) => {
                  let color= '#834e56';
                  tag = '$'+' '+tag;
                  return (
                      <Tag color={color} key={tag}>
                          {tag.toUpperCase()}
                      </Tag>
                  );
              })}
            </span>
            ),
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.importeoferta), _.isEqual),
            onFilter: (value, record) => record.importeoferta.startsWith(value)
        },{
            title: 'Estado Pago',
            dataIndex: 'estadopago',
            width: '5%',
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Observación',
            dataIndex: 'observacionesodertapago',
            width: '20%',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.observacionesodertapago), _.isEqual),
            onFilter: (value, record) => record.observacionesodertapago.startsWith(value)
        }
    ];

    useEffect(() => {
        let idsolicitudviaje;
        if(!sessionStorage.getItem('ofertas_mod')){
            navigate('/ofertas');
        }else{
            let user_mod = JSON.parse(sessionStorage.getItem('ofertas_mod'));
            idsolicitudviaje = user_mod.idsolicitudviaje;
        }
        recargar(idsolicitudviaje);
    }, []);  

    const recargar = (idsolicitudviaje) => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        Axios.post("ht/verHistorialOferta",
            {
                "idsolicitudviaje": idsolicitudviaje,
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.data.length>0){
                for(let i=0;i<response.data.data.length;i++){
                    aux.push({
                        key:response.data.data[i].idsolicitudofertaspago,
                        idsolicitudofertaspago:response.data.data[i].idsolicitudofertaspago,
                        fecharegistro:response.data.data[i].fecharegistro,
                        ofertaactual:response.data.data[i].ofertaactual,
                        idestadoofertapago:response.data.data[i].idestadoofertapago,
                        estadooferta:[response.data.data[i].estadooferta],
                        importeoferta:[response.data.data[i].importeoferta],
                        estadopago:response.data.data[i].estadopago,
                        observacionesodertapago:response.data.data[i].observacionesodertapago,
                        fechaactualizacion:response.data.data[i].fechaactualizacion,
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        })
            .catch(error => {
                setSpinning(false);
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);


            });
    };


    return (

        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
        <div >
            <p style={{textAlign:"center", margin:10, fontSize:'20px', fontWeight:'bold'}}>Historial de Ofertas</p>
            {contextHolder}
            <div
                style={{
                    marginBottom: 2,
                }}
            >
                {contextHolder}
                {/* <Space direction='horizontal'>
                    <Link to="/ofertas">
                        <Button type="primary" className="button" icon={<ArrowLeftOutlined />} loading={loading || loading}>
                            
                        </Button>
                    </Link>


                </Space> */}
            </div>

            <Row>
                <Col span={24}>
                    <Table size="small"
                          scroll={{
                              y: 300,
                          }}
                        pagination={{
                            position: [bottom],
                            pagination: {                                
                                current: 1,
                                pageSize: 10,
                            }}}
                            bordered={true}
                           //rowSelection={rowSelection}
                           columns={columns} dataSource={data}
                           onRow={(record) => {
                               return {
                                   onDoubleClick: async () => {
                                       // selectRowUpdate(record);
                                   }
                               }
                           }}/>
                </Col>
            </Row>

         </div>
        </Spin>
    );
};
export default HistorialOfertas;