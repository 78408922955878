import React, {} from 'react';
import {Navigate, Route, Routes } from "react-router-dom";
import Usuarios from "../modulos/usuarios/Usuarios";
import FormUsuario from '../modulos/usuarios/FormUsuario';
// import GoogleMap from '../modulos/mapas/UbicacionMapa';


import CaractTransporte from "../modulos/caracteristicatransporte/CaractTransporte";
import FormAddCaractTransporte from '../modulos/caracteristicatransporte/FormAddCaractTransporte';

import TipoTransporte from "../modulos/tipotransporte/TipoTransporte";
import FormAddTipoTransporte from '../modulos/tipotransporte/FormAddTipoTransporte';

import ConfRutaCharter from "../modulos/confrutacharter/ConfRutaCharter";
import FormConfRutaCharter from '../modulos/confrutacharter/FormConfRutaCharter';

import ViajesChofer from "../modulos/viajeschofer/ViajesChofer";

import ViajeCharter from "../modulos/viajecharter/ViajeCharter";
import FormAddViajeCharter from '../modulos/viajecharter/FormAddViajeCharter';
import FormModViajeCharter from '../modulos/viajecharter/FormModViajeCharter';

import ListarViajesChartersDispatcher from "../modulos/listarviajesdispatcher/ListarViajesChartersDispatcher";
import ListarViajesPersonalizadosDispatcher from "../modulos/listarviajesdispatcher/ListarViajesPersonalizadosDispatcher";
import ListarViajesCorporativosDispatcher from "../modulos/listarviajesdispatcher/ListarViajesCorporativosDispatcher";

import TipoRuta from "../modulos/tiporuta/TipoRuta";
import FormAddTipoRuta from '../modulos/tiporuta/FormAddTipoRuta';

import ViajePersonalizado from "../modulos/viajepersonalizado/ViajePersonalizado";
import FormViajePersonalizado from '../modulos/viajepersonalizado/FormViajePersonalizado';

import ViajeCorporativo from "../modulos/viajecorporatitvo/ViajeCorporativo";
import FormViajeCorporativo from '../modulos/viajecorporatitvo/FormViajeCorporativo';

import ComentariosViaje from "../modulos/comentarios/HistorialComentarios";

import SolicViajeCharter from "../modulos/solicviajecharter/SolicViajeCharter";
import StepSolicViajeCharter from '../modulos/solicviajecharter/StepSolicViajeCharter';
import FormModSolicViajeCharter from '../modulos/solicviajecharter/FormModSolicViajeCharter';

import Oferta from "../modulos/oferta/Oferta";
import FormGenOferta from "../modulos/oferta/FormGenOferta";
import HistorialOfertas from '../modulos/oferta/HistorialOfertas';
import VerVehiculos from '../modulos/oferta/VerVehiculos';

import PageWelcome from '../generales/PageWelcome';
import Omnibus from '../modulos/omnibus/Omnibus';
import FormOmnibus from '../modulos/omnibus/FormOmnibus';
import FormRevisionTecnica from '../modulos/omnibus/FormRegRevTecnica';
import FormChangePassword from './FormChangePassword';
import StepsSolicViajeCharter from '../modulos/solicviajecharter/StepsSolicViajeCharter';

import PaymentSuccess from '../modulos/payment/PaymentSuccess';
import PaymentFailed from '../modulos/payment/PaymentFailed';

import CerrarViaje from "../modulos/confvariables/CerrarViaje";
import CalcularOferta from "../modulos/confvariables/CalcularOferta";


const RoutersSystem = () => {

    let funcionalidades = (sessionStorage.getItem('funcionalidades'))? JSON.parse(sessionStorage.getItem('funcionalidades')):null;
  
    return (
            <Routes>

                <Route exact path="/principal" element={<PageWelcome />} />
                <Route path="*" element={<Navigate to='/principal' />} />
                
                /**  Todos los usuarios tendrán permiso a cambiar su propia clave  */ 
                <Route path="/changemypass/:id" element={<FormChangePassword />} />

                <Route path="/paymentsuccess" element={<PaymentSuccess />} />
                <Route path="/paymentfailed" element={<PaymentFailed />} />
                <Route path="/verhistorial" element={<HistorialOfertas />} />

                {funcionalidades.indexOf("menu_usuarios")!==-1 &&
                       //Gestión de usuarios
                     <>
                         <Route exact path="/usuarios" element={<Usuarios />} />
                         <Route path="/addusuarios" element={<FormUsuario accion = "add"/>} />
                         <Route path="/editusuarios/:id" element={<FormUsuario accion = "mod"/>} />
                         <Route path="/cambiarpass/:id" element={<FormUsuario accion = "pass"/>} />
                     </>
                }

                {funcionalidades.indexOf("menu_variables")!==-1 &&
                //Características de Transporte  y  Tipo de Transporte
                <>
                    <Route exact path="/cerrarviaje" element={<CerrarViaje />} />
                    {/*<Route path="/addcaracttransporte" element={<FormAddCaractTransporte accion = "add"/>} />*/}
                    {/*<Route path="/editcaracttransporte/:id" element={<FormAddCaractTransporte accion = "mod"/>} />*/}


                    <Route exact path="/calcularoferta" element={<CalcularOferta />} />
                    {/*<Route path="/addtipotransporte" element={<FormAddTipoTransporte accion = "add"/>} />*/}
                    {/*<Route path="/edittipotransporte/:id" element={<FormAddTipoTransporte accion = "mod"/>} />*/}

                </>
                }

                {funcionalidades.indexOf("menu_transporte")!==-1 &&
                      //Características de Transporte  y  Tipo de Transporte
                    <>
                        <Route exact path="/caracttransporte" element={<CaractTransporte />} />
                        <Route path="/addcaracttransporte" element={<FormAddCaractTransporte accion = "add"/>} />
                        <Route path="/editcaracttransporte/:id" element={<FormAddCaractTransporte accion = "mod"/>} />


                        <Route exact path="/tipotransporte" element={<TipoTransporte />} />
                        <Route path="/addtipotransporte" element={<FormAddTipoTransporte accion = "add"/>} />
                        <Route path="/edittipotransporte/:id" element={<FormAddTipoTransporte accion = "mod"/>} />
                    
                        <Route exact path="/omnibus" element={<Omnibus />} />
                        <Route path="/addomnibus" element={<FormOmnibus accion = "add"/>} />
                        <Route path="/editomnibus/:id" element={<FormOmnibus accion = "mod"/>} />
                        <Route path="/revisiontecnica/:id" element={<FormRevisionTecnica accion = "add"/>} />

                    </>
                }

                {funcionalidades.indexOf("menu_rutas")!==-1 &&
                      //Configurar Ruta Charter  y  Viaje Charter
                    <>
                        <Route exact path="/confrutacharter" element={<ConfRutaCharter />} />
                        <Route path="/addconfrutacharter" element={<FormConfRutaCharter accion = "add"/>} />
                        <Route path="/editconfrutacharter/:id" element={<FormConfRutaCharter accion = "mod"/>} />

                        <Route exact path="/tiporuta" element={<TipoRuta />} />
                        <Route path="/addtiporuta" element={<FormAddTipoRuta accion = "add"/>} />
                        <Route path="/edittiporuta/:id" element={<FormAddTipoRuta accion = "mod"/>} />

                    </>
                }

                {funcionalidades.indexOf("menu_viajes")!==-1 &&
                    //Configurar Ruta Charter  y  Viaje Charter
                    <>

                        <Route exact path="/viajecharter" element={<ViajeCharter />} />
                        <Route path="/addviajecharter" element={<FormAddViajeCharter />} />
                        <Route path="/editviajecharter/:id" element={<FormModViajeCharter/>} />

                        <Route exact path="/listarviajeschartersdispatcher" element={<ListarViajesChartersDispatcher />} />
                        <Route exact path="/listarviajespersonalizadosdispatcher" element={<ListarViajesPersonalizadosDispatcher />} />
                        <Route exact path="/listarviajescorporativosdispatcher" element={<ListarViajesCorporativosDispatcher />} />


                    </>
                }

                {funcionalidades.indexOf("menu_viajes_chofer")!==-1 &&
                //Componente para visualizar los viajes del chofer
                <>

                    <Route exact path="/viajeschofer" element={<ViajesChofer />} />


                </>
                }

                {funcionalidades.indexOf("menu_solicitudes")!==-1 &&

                <>
                    {/*Solicitar Viaje Personalizado*/}
                    <Route exact path="/viajepersonalizado" element={<ViajePersonalizado />} />
                    <Route path="/addviajepersonalizado" element={<FormViajePersonalizado accion = "add"/>} />
                    <Route path="/editviajepersonalizado/:id" element={<FormViajePersonalizado accion = "mod"/>} />

                    {/*Solicitar Viaje Corporativo*/}
                    <Route exact path="/viajecorporativo" element={<ViajeCorporativo />} />
                    <Route path="/addviajecorporativo" element={<FormViajeCorporativo accion = "add"/>} />
                    <Route path="/editviajecorporativo/:id" element={<FormViajeCorporativo accion = "mod"/>} />

                    {/*Comentarios del Viaje Terminado*/}
                    <Route exact path="/comentariosviaje" element={<ComentariosViaje />} />

                    {/*Solicitar Viaje Charter*/}
                    <Route exact path="/solicviajecharter" element={<SolicViajeCharter />} />
                    <Route path="/addsolicviajecharter" element={<StepsSolicViajeCharter/>} />
                    <Route path="/editsolicviajecharter/:id" element={<FormModSolicViajeCharter accion = "mod"/>} />

                </>
                }

                {funcionalidades.indexOf("menu_ofertas")!==-1 &&

                <>
                    {/*Ofertas*/}
                    <Route exact path="/ofertas" element={<Oferta />} />
                    <Route path="/formgeneraroferta" element={<FormGenOferta accion = "add"/>} />
                    <Route path="/formmodificaroferta" element={<FormGenOferta accion = "mod"/>} />
                    {/*<Route path="/verhistorial" element={<HistorialOfertas />} />*/}
                    <Route path="/vervehiculos" element={<VerVehiculos />} />

                </>
                }

 
            </Routes>
    );
};

export default RoutersSystem;