import React, {useContext, useState, useEffect} from 'react'
import {Image, Menu } from "antd";
import {Link } from "react-router-dom";
import { LogoutOutlined,  UserOutlined,  ContainerFilled,  CarFilled,  TrademarkOutlined,
    CopyrightOutlined,   ContainerOutlined, SendOutlined, RightOutlined, RocketOutlined,
    MailOutlined, BarsOutlined, ControlOutlined, CloseCircleOutlined, CalculatorOutlined  } from '@ant-design/icons';

import {LoginContext} from "../components/seguridad/servicios/LoginServicios";
import { FormattedMessage } from 'react-intl';
import TipoRuta from "../modulos/tiporuta/TipoRuta";
import FormAddTipoRuta from "../modulos/tiporuta/FormAddTipoRuta";

const { SubMenu } = Menu;

const REACT_APP_LOGO = process.env.REACT_APP_LOGO;

const MenuPrincipal = ({collapsed,...props}) => {
    
    const {logout} = useContext(LoginContext);

    const [openKeys, setOpenKeys] = useState([]);


    let funcionalidades = (sessionStorage.getItem('funcionalidades'))? JSON.parse(sessionStorage.getItem('funcionalidades')):null;
            

    useEffect(() => {
        if(funcionalidades != null){
            setOpenKeys(funcionalidades.slice(0,1));
        }

    }, []);  
         

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (latestOpenKey && funcionalidades.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const onMenuClick = (key) =>{
       
        if(key === '0') {
            logout();
        }
    
    };

 
	return ( 
		<Menu className = {`${collapsed ? 'collapseStyle' :'expandedStyle'}`}
           // scroll={{
           //     y: 400,
           // }}
           openKeys={openKeys}
           onOpenChange={onOpenChange}
           mode="inline"
           onClick={(e) =>
               onMenuClick(e.key)}
           style={{ overflowY: 'auto', height: '100%', borderRight: 0, backgroundColor: '#195162', color: '#ffffff' }}
           >
           <div flex ="auto" >
               <Image
                   // width={200}
                   // height={200}
                   preview={false}
                   src={REACT_APP_LOGO} />
           </div>
           <SubMenu hidden={funcionalidades.indexOf("menu_solicitudes")===-1}   key="menu_solicitudes"  icon={<SendOutlined style={{color:'white'}}/>}
               title={<FormattedMessage id="menu_solicitar_viaje" defaultMessage="Solicitudes" />}>
               <Menu.Item key="10" >
                   <RightOutlined />
                   <span  ><Link to="/solicviajecharter">
                        {<FormattedMessage id="menu_charter" defaultMessage="Charters" />}
                    </Link></span>
               </Menu.Item>
               <Menu.Item key="8" >
                    <RightOutlined />
                    <span  ><Link to="/viajepersonalizado">
                    {<FormattedMessage id="sub_menu_viaje_personalizado" defaultMessage="Personalizados" />}
                        </Link></span>
                </Menu.Item>
               {/*Esta condicion es porque este menu solo lo ve el usuario coporativo*/}
               {funcionalidades.indexOf("menu_solicitudes_corporativo")!==-1 &&
               <Menu.Item key="9" >
                   <RightOutlined />
                   <span  ><Link to="/viajecorporativo">
                    {<FormattedMessage id="sub_menu_viaje_corporativo" defaultMessage="Corporativos" />}
                        </Link></span>
               </Menu.Item>
               }

            </SubMenu>
            <SubMenu hidden={funcionalidades.indexOf("menu_rutas")===-1}   key="menu_rutas"  icon={<TrademarkOutlined style={{color:'white'}}/>}
                title={<FormattedMessage id="menu_rutas" defaultMessage="Rutas" />}>

                {/*Esta condicion es porque este menu solo lo ve el administrador*/}
                {funcionalidades.indexOf("menu_rutas_tipo_rutas")!==-1 &&
                    <Menu.Item key="7" >
                        <TrademarkOutlined />
                        <span  ><Link to="/tiporuta">
                        {<FormattedMessage id="sub_menu_tipo_rutas" defaultMessage="Tipos de Ruta" />}
                            </Link></span>
                    </Menu.Item>
                }
                <Menu.Item key="5">
                    <TrademarkOutlined />
                    <span ><Link to="/confrutacharter">
                   {<FormattedMessage id="sub_menu_gestionar_ruta" defaultMessage="Gestionar Rutas" />}
                     </Link></span>
                </Menu.Item>
            </SubMenu>
           <SubMenu hidden={funcionalidades.indexOf("menu_usuarios")===-1}   key="menu_usuarios"  icon={<UserOutlined style={{color:'white'}}/>}
                           title={<FormattedMessage id="menu_admin_user" defaultMessage="Admin usuarios" />}>
               <Menu.Item key="1" >
                   <UserOutlined />
                   <span  ><Link to="/usuarios">{<FormattedMessage id="sub_menu_users" defaultMessage="Usuarios" />}</Link></span>
               </Menu.Item>
           </SubMenu>
            <SubMenu hidden={funcionalidades.indexOf("menu_variables")===-1} key="menu_variables"  icon={<ControlOutlined style={{color:'white'}}/>}
                     title={<FormattedMessage id="menu_variables" defaultMessage="Variables" />}>
                <Menu.Item key="12">
                    <CloseCircleOutlined />
                    <span  ><Link to="/cerrarviaje">
                   {<FormattedMessage id="sub_menu_cerrar_viaje" defaultMessage="Cerrar Viaje" />}
                     </Link></span>
                </Menu.Item>
                <Menu.Item key="13">
                    <CalculatorOutlined />
                    <span ><Link to="/calcularoferta">
                   {<FormattedMessage id="sub_menu_calcular_oferta" defaultMessage="Calcular Oferta" />}
                    </Link></span>
                </Menu.Item>
            </SubMenu>
           <SubMenu hidden={funcionalidades.indexOf("menu_transporte")===-1} key="menu_transporte"  icon={<CarFilled style={{color:'white'}}/>}
           title={<FormattedMessage id="menu_transporte" defaultMessage="Transporte" />}>
               <Menu.Item key="2">
                   <ContainerFilled />
                   <span  ><Link to="/caracttransporte">
                   {<FormattedMessage id="sub_menu_caracteristicas" defaultMessage="Características" />}
                     </Link></span>
               </Menu.Item>
               <Menu.Item key="3">
                   <CarFilled />
                   <span ><Link to="/tipotransporte">
                   {<FormattedMessage id="sub_menu_tipo_transporte" defaultMessage="Tipo Transporte" />}
                    </Link></span>
               </Menu.Item>
               <Menu.Item key="4">
                   <CarFilled />
                   <span ><Link to="/omnibus">
                     {<FormattedMessage id="sub_menu_vehiculos" defaultMessage="Vehículos" />}
                    </Link></span>
               </Menu.Item>
           </SubMenu>
           <SubMenu hidden={funcionalidades.indexOf("menu_viajes")===-1} key="menu_viajes" icon={<RocketOutlined style={{color:'white'}}/>} style={{color:'white'}}
              title={<FormattedMessage id="menu_viajes" defaultMessage="Viajes" />} >
               <Menu.Item key="6">
                   <ContainerOutlined />
                   <span ><Link to="/viajecharter">
                   {<FormattedMessage id="sub_menu_listado_viaje" defaultMessage="Viajes Charters" />}
                    </Link></span>
               </Menu.Item>
               <Menu.Item key="16">
                   <RightOutlined />
                   <span ><Link to="/listarviajeschartersdispatcher">
                   {<FormattedMessage id="sub_menu_listar_viajes" defaultMessage="Charters" />}
                    </Link></span>
               </Menu.Item>
               <Menu.Item key="17">
                   <RightOutlined />
                   <span ><Link to="/listarviajespersonalizadosdispatcher">
                   {<FormattedMessage id="sub_menu_listar_viajes" defaultMessage="Personalizados" />}
                    </Link></span>
               </Menu.Item>
               <Menu.Item key="18">
                   <RightOutlined />
                   <span ><Link to="/listarviajescorporativosdispatcher">
                   {<FormattedMessage id="sub_menu_listar_viajes" defaultMessage="Corporativos" />}
                    </Link></span>
               </Menu.Item>

           </SubMenu>

            <SubMenu hidden={funcionalidades.indexOf("menu_viajes_chofer")===-1} key="menu_viajes_chofer" icon={<RocketOutlined style={{color:'white'}}/>} style={{color:'white'}}
                     title={<FormattedMessage id="menu_viajes_chofer" defaultMessage="Mis Viajes" />} >
                <Menu.Item key="12">
                    <ContainerOutlined />
                    <span ><Link to="/viajeschofer">
                   {<FormattedMessage id="sub_menu_listado_mis_viajes" defaultMessage="Mis Viajes" />}
                    </Link></span>
                </Menu.Item>


            </SubMenu>

            <SubMenu hidden={funcionalidades.indexOf("menu_ofertas")===-1}   key="menu_ofertas"  icon={<MailOutlined   style={{color:'white'}}/>}
                     title={<FormattedMessage id="menu_ofertas" defaultMessage="Ofertas" />}>
                <Menu.Item key="11" >
                    <MailOutlined  />
                    <span  ><Link to="/ofertas">
                        {<FormattedMessage id="menu_ofertas" defaultMessage="Ofertas" />}
                    </Link></span>
                </Menu.Item>

            </SubMenu>


            
           <Menu.Item key='0'>
               <LogoutOutlined style={{color:'white'}}/>
               <span >{<FormattedMessage id="menu_cerrar_session" defaultMessage="Cerrar Sesión" />}</span>
           </Menu.Item>
           {/*<SubMenu key="sub3" icon={<MenuFoldOutlined />} title="Menu 3">*/}
           {/*    <Menu.Item key="5">Submenu Item 5</Menu.Item>*/}
           {/*    <Menu.Item key="6">Submenu Item 6</Menu.Item>*/}
           {/*</SubMenu>*/}
       </Menu>
    );
}
 
export default MenuPrincipal;