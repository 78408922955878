import React, { useContext, useEffect, useRef, useState } from 'react';
import { Popconfirm, Form, InputNumber,Input, message, Typography , Spin, Table, Layout } from 'antd';
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber min={0}/> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const StepCantDisponibles = ({setDisabled}) => { 

  const navigate = useNavigate();
  const headers = {
    'accept': '*/*',
    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
    'Content-Type': 'application/json',
  };
  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage(); 

  const [spinning, setSpinning] = useState(false);
  
  const [spinTitle, setSpinTitle] = useState("Cargando datos...");

  const [cantadultos, setCantAdultos] = useState(0);

  const [cantninnos, setCantNinnos] = useState(0);

  const [cantsillaruedas, setCantSillaruedas] = useState(0);

  const [total, setTotal] = useState(0);

  const changeDisabled = (value) => {
    setDisabled(value)
  }

  const [data, setData] = useState([]);

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      if(sessionStorage.getItem('data_distribucion'))
        sessionStorage.removeItem('data_distribucion');

      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
        
        // Validar la distribucion

         let totalDistribucion = 0;
         for(var i = 0; i<newData.length;i++){
            totalDistribucion += parseInt(newData[i].distribucion);    
         }

        changeDisabled(totalDistribucion !== total);

        sessionStorage.setItem('data_distribucion', JSON.stringify(newData));

      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

 
  useEffect(() => {
    changeDisabled(true);
    if(!sessionStorage.getItem('ruta') || !sessionStorage.getItem('cantadultos')){
        navigate('/solicviajecharter');
    }else{
    setCantAdultos(parseInt(sessionStorage.getItem('cantadultos')));
    setCantNinnos(parseInt(sessionStorage.getItem('cantninnos')));
    setCantSillaruedas(parseInt(sessionStorage.getItem('cantsillaruedas')));
    setTotal(parseInt(sessionStorage.getItem('cantadultos')) +
                 parseInt(sessionStorage.getItem('cantninnos')));


    let ruta_seleccionada = JSON.parse(sessionStorage.getItem('ruta'));

    let aux = [];

    Axios.post("ht/getVehiculosByIdViajeCharterFecha",
    {
        "idviajecharterfecha": ruta_seleccionada.idviajecharterfecha
      },
        {
            headers: headers
        }
    ).then(response => {
        setSpinning(false);
        // console.log('usuarios', response.data);
        if(response.data.data.length>0){
            for(var i=0;i<response.data.data.length;i++){
                aux.push({
                    key:response.data.data[i].idomnibus,
                    idomnibus:response.data.data[i].idomnibus,
                    denominacion:response.data.data[i].denominacion,
                    idviajecharterfecha:response.data.data[i].idviajecharterfecha,
                    idviajevehiculo:response.data.data[i].idviajevehiculo,
                    sillaruedas:response.data.data[i].sillaruedas,
                    capacidad:response.data.data[i].capacidad,
                    cantidadocupada:response.data.data[i].cantidadocupada,
                    disponibles:response.data.data[i].disponibles,
                    distribucion:0
                });
            }
        }

        setData(aux);
    })
        .catch(error => {
            setSpinning(false);
        });
    }
   
  }, []);

  const defaultColumns = [
    {
      title: 'Vehículos',
      dataIndex: 'denominacion',
      width: '30%',
    },
    {
      title: 'Capacidad',
      dataIndex: 'capacidad',
    },
    {
        title: 'Ocupados',
        dataIndex: 'cantidadocupada',
    },
    {
        title: 'Disponibles',
        dataIndex: 'disponibles',
    },
    {
      title: 'Mi distribución',
      dataIndex: 'distribucion',
      width: '30%',
      editable: true,
    },
    {
      title: 'Operación',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Salvar o cancelar?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'distribucion' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const { Header, Content } = Layout;

  const headerStyle = {
    textAlign: 'center',
    color: '#000000',
    height: 64,
    paddingInline: 48,
    lineHeight: '64px',
    backgroundColor: '#ffff',
  };
  const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
  };

  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
  };


  return (
    <Spin spinning={spinning} tip={spinTitle} size={"large"}>
    {contextHolder}
    <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <span style={{fontWeight:'bold', color:'#FF0000'}}>Está planificando para Adultos: {cantadultos}, Niños: {cantninnos} y
                      Silla de ruedas: {cantsillaruedas}, con un total de {total} capacidad(es).  </span>
        </Header>
        <Content style={contentStyle}>
        <Form form={form} component={false}>
                <Table
                        size="small"
                        scroll={{
                            y: 300
                        }}
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={data}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                    position: ["bottomCenter"],
                    pagination: {
                        current: 1,
                        pageSize: 10,
                    }
                  }}
                />
          </Form>
      </Content>
    </Layout>  
    </Spin>
  );
};
export default StepCantDisponibles;