import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import {Checkbox, Row, Col, Form, Space, Button, message, Spin, Table, DatePicker, Select  } from "antd";

 
import {IoReload, IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import dayjs from 'dayjs';
import { bool } from "prop-types";
import _ from "lodash";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});


const FormAddViajeCharter = ({}) => {

    const navigate = useNavigate();

    const { Option } = Select;

    const [form] = Form.useForm();

    const [data, setData] = useState([]);
    const [original, setOriginal] = useState([]);
    const [bottom, setBottom] = useState('bottomCenter');

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));
    
    const columns = [
        {
            title: 'Nombre Ruta',
            dataIndex: 'nombreruta',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.nombreruta), _.isEqual),
            onFilter: (value, record) => record.nombreruta.startsWith(value),
        },{
            title: 'Origen',
            dataIndex: 'origen',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.origen), _.isEqual),
            onFilter: (value, record) => record.origen.startsWith(value),
        },{
            title: 'Destino',
            dataIndex: 'destino',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.destino), _.isEqual),
            onFilter: (value, record) => record.destino.startsWith(value),
        },{
            title: 'Hora Salida',
            dataIndex: 'horasalida',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.horasalida), _.isEqual),
            onFilter: (value, record) => record.horasalida.startsWith(value),
        },{
            title: 'Hora Llegada',
            dataIndex: 'horallegada',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.horallegada), _.isEqual),
            onFilter: (value, record) => record.horallegada.startsWith(value),
        },{
            title: 'Precio Adulto',
            dataIndex: 'precioruta',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.precioruta), _.isEqual),
            onFilter: (value, record) => record.precioruta.startsWith(value),
        },{
            title: 'Precio Niño',
            dataIndex: 'preciorutaninno',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.preciorutaninno), _.isEqual),
            onFilter: (value, record) => record.preciorutaninno.startsWith(value),
        },{
            title: 'Tipo Transporte',
            dataIndex: 'tipotransporte',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.tipotransporte), _.isEqual),
            onFilter: (value, record) => record.tipotransporte.startsWith(value),
        }/*,{
            title: 'Marca',
            dataIndex: 'marca',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.marca), _.isEqual),
            onFilter: (value, record) => record.marca.startsWith(value),
        }*/,{
            title: 'Regresa',
            dataIndex: 'regresa',
            filters: [
                { text: 'Regresa', value: "1" },
                { text: 'No Regresa', value: "0" },
              ],
            filterMultiple: false,
            onFilter: (value, record) => record.regresa.startsWith(value),
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        }
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const [messageApi, contextHolder] = message.useMessage();

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando información ...");

    const [loading, setLoading] = useState(false);

    const [vehiculosOriginal, setVehiculosOriginal] = useState([]);

    const [vehiculos, setVehiculos] = useState([]);

    const [deshabilitar, setDesHabilitar] = useState(true);

    const loadVehiculos = (idtipotransporte) => {
        setLoading(true);
        let aux = [];
        form.setFieldsValue({
            "idomnibus": []});

        let chequeados = [];    

        Axios.post("ht/getVehiculo",
                {
                    "filtros": [
                      {
                        "idtipotransporte": idtipotransporte,
                        "idviajecharterfecha":""
                      }
                    ]
                  },
            {
                headers: headers
            }
        ).then(response => {
                setLoading(false);
                let existe = [];
                if(response.data.data.length > 0){
                    for(var i=0;i<response.data.data.length;i++){
                            aux.push({
                                id: response.data.data[i].idomnibus,
                                denominacion: response.data.data[i].denominacion,
                                chequeado: response.data.data[i].chequeado,
                                enuso: response.data.data[i].enuso,
                            });
    
                            if(response.data.data[i].chequeado == 1){
                                chequeados.push(response.data.data[i].idomnibus);
                            }
                    }
                }
                setVehiculos(aux);
                setVehiculosOriginal(aux);
                setDesHabilitar(false);

                form.setFieldsValue({
                    "idomnibus": chequeados});
            })
            .catch(error => {
                setLoading(false);
                setVehiculos(aux);
                setVehiculosOriginal(aux);
                setDesHabilitar(false);
                form.setFieldsValue({
                    "idomnibus": chequeados});
                console.log("Error",error);
            })
    };


    const recargar = () => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        let start = 0;
        let limit = 1000;

        Axios.get(`ht/getRutaCharter?start=${start}&limit=${limit}`,
            {
                headers: headers
            })
            .then(response => {
                setSpinning(false);
                if(response.data.data.length>0){
                    for(var i=0;i<response.data.data.length;i++){
                        aux.push({
                            key:response.data.data[i].idrutacharter,
                            idrutacharter:response.data.data[i].idrutacharter,
                            nombreruta:response.data.data[i].nombreruta,
                            origen:response.data.data[i].origen,
                            destino:response.data.data[i].destino,
                            horasalida:response.data.data[i].horasalida,
                            horallegada:response.data.data[i].horallegada,
                            precioruta:response.data.data[i].precioruta,
                            preciorutaninno:response.data.data[i].preciorutaninno,
                            idtipotransporte:response.data.data[i].idtipotransporte,
                            tipotransporte:response.data.data[i].tipotransporte,
                            marca:response.data.data[i].marca,
                            regresa:response.data.data[i].regresa,
                        });
                    }
                }

                setData(aux);
                setOriginal(aux);
            })
            .catch(error => {
                setSpinning(false);
            })
    };
    

    useEffect(() => {
        recargar();
    }, []);

    
    const tailLayout = {
        wrapperCol: { marginTop:5, offset: 8, span: 16 },
      };


    const cancelar = (values) => {
        navigate('/viajecharter');
    };
    
    const onChange = (date, dateString) => {
        console.log(dateString);
    };

    const onSelectChange = async (newSelectedRowKeys)  => {
        setSelectedRowKeys(newSelectedRowKeys);
        let selected = await getRecordSelected(newSelectedRowKeys[0]);
        form.setFieldsValue({ rutas: selected});
        loadVehiculos(selected.idtipotransporte);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getRecordSelected = async (id) => {
        for(var i=0;i<original.length;i++){
            if(original[i].idrutacharter === id){
                return original[i];
            }
        }
        return [];
    };


    const onFinish = (values) => {

        // console.log("Datos del Form", values);

        let error = false;

        if(values.rutas === undefined){
            error = true;
            messageApi.open({
                type: 'error',
                content: 'Por favor debe seleccionar al menos una ruta!',
                duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);
        }

        
        if(!error){
            let fechas = [];
            values.fechaviaje.forEach(function (item) {
                let day = (item.$D<10)?"0"+item.$D:item.$D;
                let $M = item.$M+1;
                let month = ($M<10)?"0"+$M:$M;
                let date = day+"/"+month+"/"+item.$y;
                fechas.push(date);
              });

            setSpinTitle('Guardando datos...');
            Axios.post("ht/addViajeCharterFecha",
                    {
                        "idusuarioregistra": sessionStorage.getItem('idusuario'),
                        "idrutacharter": values.rutas.idrutacharter,
                        "nombreruta": values.rutas.nombreruta,
                        "origen": values.rutas.origen,
                        "destino": values.rutas.destino,
                        "horasalida": values.rutas.horasalida,
                        "horallegada": values.rutas.horallegada,
                        "precioruta": values.rutas.precioruta,
                        "preciorutaninno": values.rutas.preciorutaninno,
                        "regresa": (values.rutas.regresa=="1")?1:0,
                        "activa": (values.activa)?1:0,
                        "sillaruedas": (values.sillaruedas)?1:0,
                        "sobreventa": (values.sobreventa)?1:0,
                        "idtipotransporte": values.rutas.idtipotransporte,
                        "fechaviaje": fechas,
                        "idomnibus":values.idomnibus
                      },
                    {
                        headers: headers
                      }
                ).then(response => {
                  setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        navigate('/viajecharter');
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al guardar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
            })
                .catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar los datos',
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);


                });
        }
        
      };
      const onFinishFailed = (errorInfo) => {
       
      };

      const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
      };

      const changeVehiculos = (value) => {
        let enuso = value;
        for(var i=0;i<vehiculosOriginal.length;i++){
            if(vehiculosOriginal[i].enuso == 1 && value.indexOf(vehiculosOriginal[i].id)==-1){
                enuso.push(vehiculosOriginal[i].id);
            }
         }
         form.setFieldsValue({
            "idomnibus": enuso});
      };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
             <div display="flex" align="middle">
            <p style={{textAlign:"center", margin:15, fontSize:'18px', fontWeight:'bold'}}>Adicionar Viaje Charter</p>
            {contextHolder}
             <Form
                form={form}
                autoComplete="on"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                name="form_in_modal"
                initialValues={{
                    activa: true
                   
                }}
            >

                 <Row align='middle' justify='center'>
                       <Col style={{margin:0}} >
                       <Form.Item
                                name="fechaviaje"
                                label={
                                    <p style={{fontWeight:'bold'}}>Fechas</p>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor seleccionar al menos una fecha!',
                                    }
                                ]}
                                    >
                                    
                                        <DatePicker
                                         style={{width:400}}
                                          maxTagCount="responsive" 
                                          disabledDate={disabledDate}
                                          multiple onChange={onChange} 
                                          format='DD/MM/YYYY'
                                          />

                                          </Form.Item>
                        </Col>
                        {/*<Col style={{margin:5}} >*/}
                        {/*<Form.Item*/}
                        {/*         name="idomnibus"*/}
                        {/*         label="Vehículos"*/}
                        {/*         rules={[*/}
                        {/*             {*/}
                        {/*                 required: true,*/}
                        {/*                 message: 'Debe seleccionar al menos una opción!',*/}
                        {/*                 type: 'array',*/}
                        {/*             },*/}
                        {/*         ]}*/}
                        {/*     >*/}
                        {/*         <Select */}
                        {/*          style={{width:350}}*/}
                        {/*          disabled={deshabilitar}*/}
                        {/*          onChange={changeVehiculos}*/}
                        {/*          mode="multiple"*/}
                        {/*          placeholder="Seleccionar vehículos" */}
                        {/*          loading={loading}>*/}
                        {/*             {vehiculos.map((features) => (*/}
                        {/*                 <Option value={features.id}>*/}
                        {/*                     {features.denominacion}*/}
                        {/*                 </Option>*/}
                        {/*             ))}*/}
                        {/*         </Select>*/}
                        {/*     </Form.Item>*/}
                        {/*</Col>*/}
                        
                        {/*<Col style={{marginBottom:20, marginLeft:10}}>*/}
                        {/*  <Form.Item*/}
                        {/*                   name="activa"*/}
                        {/*                   label="Activa"*/}
                        {/*                   valuePropName="checked"*/}
                        {/*                   noStyle*/}
                        {/*               >*/}
                        {/*                   <Checkbox ><p style={{fontWeight:'bold'}}>Activa</p></Checkbox>*/}
                        {/*               </Form.Item>*/}
                        {/*</Col>*/}
                        {/*<Col style={{marginBottom:20, marginLeft:10}}>*/}
                        {/*<Form.Item*/}
                        {/*                 name="sobreventa"*/}
                        {/*                 label="Sobreventa"*/}
                        {/*                 valuePropName="checked"*/}
                        {/*                 noStyle*/}
                        {/*             >*/}
                        {/*                 <Checkbox ><p style={{fontWeight:'bold'}}>Sobreventa</p></Checkbox>*/}
                        {/*             </Form.Item>*/}

                        {/*</Col>*/}
                        {/*<Col style={{marginBottom:20, marginLeft:10}}>*/}
                        {/*<Form.Item*/}
                        {/*                 name="sillaruedas"*/}
                        {/*                 label="sillaruedas"*/}
                        {/*                 valuePropName="checked"*/}
                        {/*                 noStyle*/}
                        {/*             >*/}
                        {/*                 <Checkbox ><p style={{fontWeight:'bold'}}>Silla de ruedas</p></Checkbox>*/}
                        {/*             </Form.Item>*/}
                        {/*</Col>*/}
                    </Row>
                
                
                <Form.Item style={{margin:0}} name='rutas'>
                    
                    <Table size="small"
                                 scroll={{
                                     y: 200,
                                 }}
                                 pagination={{
                                     position: [bottom],
                                     pagination: {
                                         current: 1,
                                         pageSize: 10,
                                     }}}
                                  rowSelection={{
                                      type: "radio",
                                      ...rowSelection,
                                  }}
                                  columns={columns} dataSource={data}
                                  onRow={(record) => {
                                      return {
                                          onDoubleClick: async () => {
                                              // selectRowUpdate(record);
                                              console.log(record);
                                          }
                                      }
                                  }}/>


                </Form.Item>

                 <Row align='middle' justify='center'>
                     {/*<Col style={{margin:0}} >*/}
                     {/*    <Form.Item*/}
                     {/*        name="fechaviaje"*/}
                     {/*        label={*/}
                     {/*            <p style={{fontWeight:'bold'}}>Fechas</p>*/}
                     {/*        }*/}
                     {/*        rules={[*/}
                     {/*            {*/}
                     {/*                required: true,*/}
                     {/*                message: 'Por favor seleccionar al menos una fecha!',*/}
                     {/*            }*/}
                     {/*        ]}*/}
                     {/*    >*/}

                     {/*        <DatePicker*/}
                     {/*            style={{width:400}}*/}
                     {/*            maxTagCount="responsive"*/}
                     {/*            disabledDate={disabledDate}*/}
                     {/*            multiple onChange={onChange}*/}
                     {/*            format='DD/MM/YYYY'*/}
                     {/*        />*/}

                     {/*    </Form.Item>*/}
                     {/*</Col>*/}
                     <Col style={{margin:5}} >
                     <Form.Item
                              name="idomnibus"
                              label="Vehículos"
                              rules={[
                                  {
                                      required: true,
                                      message: 'Debe seleccionar al menos una opción!',
                                      type: 'array',
                                  },
                              ]}
                          >
                              <Select
                               style={{width:350}}
                               disabled={deshabilitar}
                               onChange={changeVehiculos}
                               mode="multiple"
                               placeholder="Seleccionar vehículos"
                               loading={loading}>
                                  {vehiculos.map((features) => (
                                      <Option value={features.id}>
                                          {features.denominacion}
                                      </Option>
                                  ))}
                              </Select>
                          </Form.Item>
                     </Col>

                     <Col style={{marginBottom:20, marginLeft:10}}>
                       <Form.Item
                                        name="activa"
                                        label="Activa"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        <Checkbox ><p style={{fontWeight:'bold'}}>Activa</p></Checkbox>
                                    </Form.Item>
                     </Col>
                     <Col style={{marginBottom:20, marginLeft:10}}>
                     <Form.Item
                                      name="sobreventa"
                                      label="Sobreventa"
                                      valuePropName="checked"
                                      noStyle
                                  >
                                      <Checkbox ><p style={{fontWeight:'bold'}}>Sobreventa</p></Checkbox>
                                  </Form.Item>

                     </Col>
                     <Col style={{marginBottom:20, marginLeft:10}}>
                     <Form.Item
                                      name="sillaruedas"
                                      label="sillaruedas"
                                      valuePropName="checked"
                                      noStyle
                                  >
                                      <Checkbox ><p style={{fontWeight:'bold'}}>Silla de ruedas</p></Checkbox>
                                  </Form.Item>
                     </Col>
                 </Row>
                    

                 
                <Form.Item {...tailLayout}>
                            <Space>

                                <Button danger onClick={cancelar}>
                                    Cancelar
                                  </Button>

                                <Button type="primary"  htmlType="submit">
                                    Guardar
                                </Button>


                            </Space>
                </Form.Item>
                
            </Form>

        </div>
        </Spin>
       
    );
};

export default FormAddViajeCharter;