import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Modal, Select, Spin, Space, Row, Col, Tag, Input, TextArea, Rate} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {IoReload, IoCloseCircle, IoCheckmarkCircle} from "react-icons/io5";
import {ArrowLeftOutlined, CloseCircleOutlined, CommentOutlined} from '@ant-design/icons';
 
import Search from "antd/es/input/Search";
import _ from "lodash";
import dayjs from "../viajepersonalizado/FormViajePersonalizado";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});
const { Option } = Select;

const HistorialComentarios = () => {

    const [bottom, setBottom] = useState('bottomCenter');

    const navigate = useNavigate();

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando usuarios...");

    const [messageApi, contextHolder] = message.useMessage();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [comentarioButtonDisabled, setComentarioButtonDisabled] = useState(true);

    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);
    const [loading/*, setLoading*/] = useState(false);
    const [idsolicitudviaje, setIdsolicitudviaje] = useState([]);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const { TextArea } = Input;

    const columns = [
        {
            title: 'Fecha Registro',
            dataIndex: 'fecharegistro',
            //width: 80,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.fecharegistro), _.isEqual),
            onFilter: (value, record) => record.fecharegistro.startsWith(value),
        },{
            title: 'Comentario',
            dataIndex: 'comentario',
            //width: 100,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.fecharegistro), _.isEqual),
            onFilter: (value, record) => record.fecharegistro.startsWith(value),
        },{
            title: 'Calificación',
            dataIndex: 'calificacion',
            //width: '20%',
            render: (text) => <Rate disabled defaultValue={text} />,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.calificacion), _.isEqual),
            onFilter: (value, record) => record.calificacion.startsWith(value),
        },{
            title: 'Usuario',
            dataIndex: 'usuariocomentario',
            //width: '20%',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.fecharegistro), _.isEqual),
            onFilter: (value, record) => record.fecharegistro.startsWith(value),
        }
    ];

    useEffect(() => {
        let idsolicitudviaje;
        if(!sessionStorage.getItem('dataSessionStorage')){
            //navigate('/ofertas');
        }else{

            /* if(sessionStorage.getItem('dataSessionStorage')){
                console.log('dataSessionStorage');
            }else if(sessionStorage.getItem('user_mod')){
                console.log('user_mod');
            } */

            let user_mod = JSON.parse(sessionStorage.getItem('dataSessionStorage'));
            idsolicitudviaje = user_mod.idsolicitudviaje;
        }
        recargar(idsolicitudviaje);

    }, []);  

    const recargar = (idsolicitudviaje) => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        Axios.post("ht/listarComentarioViaje",
            {
                "idsolicitudviaje": idsolicitudviaje,
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.data.length>0){
                for(let i=0;i<response.data.data.length;i++){
                    aux.push({
                        key:response.data.data[i].idviajecomentario,
                        idviajecomentario:response.data.data[i].idviajecomentario,
                        idsolicitudviaje:response.data.data[i].idsolicitudviaje,
                        comentario:response.data.data[i].comentario,
                        fecharegistro:response.data.data[i].fecharegistro,
                        idusuariocrea:response.data.data[i].idusuariocrea,
                        calificacion:response.data.data[i].calificacion,
                        usuariocomentario:response.data.data[i].usuariocomentario,
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        })
            .catch(error => {
                setSpinning(false);
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);


            });
    };

    const hasSelected = selectedRowKeys.length > 0;

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);

        let info_user = JSON.parse(sessionStorage.getItem('info_user'));

        if (newSelectedRowKeys.length === 1) {
            const selectedRow = data.find(row => row.key === newSelectedRowKeys[0]);
            if (selectedRow) {
                setComentarioButtonDisabled(selectedRow.idusuariocrea != info_user.idusuario);
            }
        }else{
            setComentarioButtonDisabled(true);
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [visibleCancel, setVisibleCancel] = useState(false);
    const [comentario, setComentario] = useState('');
    const [calificacion, setCalificacion] = useState(3);

    const showCancelModal = () => {
        setComentario('');
        setCalificacion(3);
        setVisibleCancel(true);
    };

    const handleCancelarComentario = () => {
        setVisibleCancel(false);
    };


    const handleOkComentario = () => {

        let idsolicitudviaje;
        let user_mod = JSON.parse(sessionStorage.getItem('dataSessionStorage'));
        let info_user = JSON.parse(sessionStorage.getItem('info_user'));
        idsolicitudviaje = user_mod.idsolicitudviaje;

        setVisibleCancel(false);

        Axios.post("ht/addComentarioViaje",
            {
                "idsolicitudviaje": idsolicitudviaje,
                "comentario": comentario,
                "idusuariocrea": info_user.idusuario,
                "calificacion": calificacion,
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

            }else{
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
            .catch(error => {
                setSpinning(false);
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

            });

        recargar(idsolicitudviaje);

    };

    const eliminar = (e) => {

        let idsolicitudviaje;
        let user_mod = JSON.parse(sessionStorage.getItem('dataSessionStorage'));
        let info_user = JSON.parse(sessionStorage.getItem('info_user'));
        idsolicitudviaje = user_mod.idsolicitudviaje;

        Modal.confirm({
            title: 'Eliminar datos!',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea eliminar los datos seleccionados?</span>
                </div>
            ),
            onOk() {
                setSpinTitle("Eliminando datos...");
                setSpinning(true);
                Axios.delete('ht/deleteComentarioViaje',
                    {
                      data: {
                        "idviajecomentario": selectedRowKeys[0]
                      },
                      headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar(idsolicitudviaje);
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);

                    });
            },
        });
    };




    return (

        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
        <div >
            <p style={{textAlign:"center", margin:10, fontSize:'20px', fontWeight:'bold'}}>Historial de Comentarios</p>
            {contextHolder}
            <div
                style={{
                    marginBottom: 2,
                }}
            >
                {contextHolder}
                <Space direction='horizontal'>
                    {/* <Link to="/viajecorporativo">
                        <Button type="primary" className="button" icon={<ArrowLeftOutlined />} loading={loading || loading}>
                            
                        </Button>
                    </Link> */}
                    <Button style={{marginRight:"0px"}} type="primary" className="button" icon={<CommentOutlined />} onClick={showCancelModal}>
                        Insertar Comentario
                    </Button>

                    <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" danger className="button" icon={<AiFillDelete />} onClick={eliminar}
                                disabled={comentarioButtonDisabled}>
                            Eliminar Comentario
                        </Button>


                </Space>
            </div>

            <Modal
                title="Insertar Comentario"
                visible={visibleCancel}
                onOk={handleOkComentario}
                onCancel={handleCancelarComentario}
                style={{ textAlign: 'center' }}
                centered
                okButtonProps={{ disabled: comentario.trim() === '' }}
            >
                {/*<p>Comentario:</p>*/}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <TextArea
                        placeholder="Escriba aquí su comentario..."
                        rows={4}
                        style={{
                            height: 100,
                            resize: 'none',
                        }}
                        value={comentario}
                        onChange={(e) => setComentario(e.target.value)}
                        // onChange={onChangeCancelSolicitud}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '16px' }}>
                        <label htmlFor="valoracion" style={{ marginRight: '8px' }}>Valoración:</label>

                        <Rate onChange={setCalificacion} value={calificacion} />

                    </div>

                </div>
            </Modal>

            <Row>
            <Col span={24}>
                    <Table size="small"
                           scroll={{
                               x: 300,
                           }}
                           //bordered={true}
                           pagination={{
                               position: [bottom],
                               pagination: {
                                   current: 1,
                                   pageSize: 10,
                               }}}
                           rowSelection={rowSelection}
                           columns={columns} dataSource={data}
                           onRow={(record) => {
                               return {
                                   onDoubleClick: async () => {
                                       //selectRowUpdate(record);
                                   }
                               }
                           }}/>
                </Col>
            </Row>

         </div>
        </Spin>
    );
};
export default HistorialComentarios;