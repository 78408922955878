import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import { Button, Table, message, Form, Modal, Spin, Space, Row, Col, Tag, Tooltip, Input, Select } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";

const { Option } = Select;
const EditableContext = React.createContext(null);

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} es requerido.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const CalcularOferta = () => {
    const [data, setData] = useState([]);
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando datos...");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    useEffect(() => {
        recargar();
    }, []);

    const recargar = () => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        Axios.get(`ht/listarVariablesOfertasPago`, { headers: headers })
            .then(response => {
                setSpinning(false);
                if (response.data.data.length > 0) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        aux.push({
                            key: response.data.data[i].idvariablesofertapago,
                            idvariablesofertapago: response.data.data[i].idvariablesofertapago,
                            denominacion: response.data.data[i].denominacion,
                            capminima: response.data.data[i].capminima,
                            capmaxima: response.data.data[i].capmaxima,
                            preciomilla: response.data.data[i].preciomilla,
                            preciohora: response.data.data[i].preciohora,
                            preciopernoctar: response.data.data[i].preciopernoctar,
                        });
                    }
                }

                setData(aux);
            })
            .catch(error => {
                setSpinning(false);
            });
    };

    const handleSave = (row) => {
        const newData = [...data];
        const index = newData.findIndex(item => row.key === item.key);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setData(newData);
            saveData(newData[index]);
        } else {
            newData.push(row);
            setData(newData);
            saveData(row);
        }
    };

    const saveData = (row) => {
        Axios.post("ht/actualizarVariablesOfertasPago", {
            idvariablesofertapago: row.idvariablesofertapago,
            capminima: row.capminima,
            capmaxima: row.capmaxima,
            preciomilla: row.preciomilla,
            preciohora: row.preciohora,
            preciopernoctar: row.preciopernoctar
        }, {
            headers: headers
        }).then(response => {
            messageApi.success('Datos actualizados correctamente');
        }).catch(error => {
            messageApi.error('Error al actualizar los datos');
        });
    };

    const columns = [
        {
            title: 'Denominación',
            dataIndex: 'denominacion',
            width: 200,
        },
        {
            title: 'Capacidad Mínima',
            dataIndex: 'capminima',
            width: 200,
            editable: true,
            render: (text) => (
                <Tag color='green'>
                    {text.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Capacidad Máxima',
            dataIndex: 'capmaxima',
            width: 200,
            editable: true,
            render: (text) => (
                <Tag color='red'>
                    {text.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Precio Milla',
            dataIndex: 'preciomilla',
            width: 200,
            editable: true,
            render: (text) => (
                <Tag color='purple'>
                    {text.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Precio Hora',
            dataIndex: 'preciohora',
            width: 200,
            editable: true,
            render: (text) => (
                <Tag color='blue'>
                    {text.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Precio Pernoctar',
            dataIndex: 'preciopernoctar',
            width: 200,
            editable: true,
            render: (text) => (
                <Tag color='geekblue'>
                    {text.toUpperCase()}
                </Tag>
            ),
        },
    ];

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        };
    });

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div>
                <p style={{ textAlign: "center", margin: 0, fontSize: '20px', fontWeight: 'bold' }}>Variables Calcular Oferta</p>
                {contextHolder}
                <div style={{ marginBottom: 10 }}>
                    <Space direction='horizontal'>
                    </Space>
                </div>
                <Row>
                    <Col span={24}>
                        <Table
                            size="small"
                            bordered
                            pagination={false}
                            components={{
                                body: {
                                    row: EditableRow,
                                    cell: EditableCell,
                                },
                            }}
                            columns={mergedColumns}
                            dataSource={data}
                        />
                    </Col>
                </Row>
            </div>
        </Spin>
    );
};

export default CalcularOferta;
