import {Checkbox, Form, Input, Space, Button, message, Spin, InputNumber, TimePicker, DatePicker, Row, Col, Card} from "antd";
import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import GlobalSelect from "../../generales/GlobalSelect";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormGenOferta = ({accion}) => {

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    // Obtener la fecha actual
    const currentDate = moment();

    const [messageApi, contextHolder] = message.useMessage();

    //console.log({accion});

    const { TextArea } = Input;

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando datos...");

    const [title] = useState("Generar Oferta");

    const [horaSalida, setHoraSalida] = useState([]);

    // const [horaRegreso, setHoraRegreso] = useState([]);

    const [idusuarioSession, setIdusuarioSession] = useState([]);

    const [correoSession, setCorreoSession] = useState([]);

    const [telefonoSession, setTelefonoSession] = useState([]);

    // const [horaLlegada, setHoraLlegada] = useState([]);

    const [flagHoraSalida, setFlagHoraSalida] = useState(false);

    // const [flagHoraRegreso, setFlagHoraRegreso] = useState(false);

    const [idsolicitudviaje, setidsolicitudviaje] = useState([]);

    const [idCategoriaServicio, setIdCategoriaServicio] = useState([]);

    const [tipotransporte, setTipotransporte] = useState([]);

    const [estado, setEstado] = useState(true);

    const [checkedPernoctar, setCheckedPernoctar] = useState(false);

    const [valueTiempoEstimado, setValueTiempoEstimado] = useState();

    const [valueDistancia, setValueDistancia] = useState();

    // const [componentDisabled, setComponentDisabled] = useState(true);

    const [loading, setLoading] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const navigate = useNavigate();

    const { id } = useParams();

    const [form] = Form.useForm();

    dayjs.extend(customParseFormat);
    const onTimePickerSalidaChange = (time, timeString) => {
        setHoraSalida(timeString);
        setFlagHoraSalida(true);
    };

    // const onTimePickerRegresoChange = (time, timeString) => {
    //     setHoraRegreso(timeString);
    //     setFlagHoraRegreso(true);
    // };

    useEffect(() => {
        cargarTipoTransporte();

        let info_user = JSON.parse(sessionStorage.getItem('info_user'));
        setCorreoSession(info_user.correo);
        setTelefonoSession(info_user.telefono);
        setIdusuarioSession(info_user.idusuario);

        let ofertas_mod = JSON.parse(sessionStorage.getItem('ofertas_mod'));
        //console.log({ofertas_mod});

        setidsolicitudviaje(ofertas_mod.idsolicitudviaje);
        setIdCategoriaServicio(ofertas_mod.idcategoriaservicio);
        setHoraSalida(ofertas_mod.horasalida);

         /* if (ofertas_mod.pernoctar === "1") {
             setEstado(false);
         }else{
             setEstado(true);
             //si regresa viene en 0
             // ofertas_mod.fecharegreso = false;
             // ofertas_mod.horaregreso = false;
         } */

        /* if (accion == "add") {
            ofertas_mod.pernoctar = 0;
            ofertas_mod.condicionesrestriccionespago = '';
            ofertas_mod.detallesproveedor = '';
            ofertas_mod.importeoferta = '';
            ofertas_mod.observaciones = '';
        } */

            //console.log({ofertas_mod});

        form.setFieldsValue({
            "idsolicitudviaje": idsolicitudviaje,
            "idcategoriaservicio": idCategoriaServicio, // Personalizado
            "idviajecharterfecha": null,
            "origen": ofertas_mod.origen,
            "destino": ofertas_mod.destino,
            "fechaviaje": dayjs(ofertas_mod.fechaviaje, 'DD/MM/YYYY'),
            // "fechaviaje": fecha,
            "horasalida": dayjs(ofertas_mod.horasalida, 'HH:mm'),
            "correoregistro": correoSession,
            "telefonousuariosolic": telefonoSession,
            "tipotransporte": ofertas_mod.idtipotransporte,
            "cantninnos": ofertas_mod.cantninnos,
            "cantadultos": ofertas_mod.cantadultos,
            "cantidadpasajeros": ofertas_mod.cantidadpasajeros,
            "cantsillaruedas": ofertas_mod.cantsillaruedas,
            "idusuariocrea": idusuarioSession,
            "caracteristicaomnibus": ofertas_mod.caracteristicaomnibus.replace(/,/g, ' / '),
            "fecharegreso": (ofertas_mod.fecharegreso)?dayjs(ofertas_mod.fecharegreso, 'DD/MM/YYYY'):'',
            "horaregreso": (ofertas_mod.horaregreso)?dayjs(ofertas_mod.horaregreso, 'HH:mm'):'',
            "pernoctar":(ofertas_mod.pernoctar)?1:0,
            "condicionesrestriccionespago": ofertas_mod.condicionesrestriccionespago,
            "detallesproveedor": ofertas_mod.detallesproveedor,
            "importe": ofertas_mod.importeoferta[0],
            "observaciones": ofertas_mod.observaciones,
        });


    }, []);

    const tailLayout = {
        wrapperCol: { marginTop:10, offset: 8, span: 16 },
      };


    useEffect(() => {
    }, []);

    const cancelar = (values) => {
        navigate('/ofertas');
    };

    const calcularPrecio = () => {
        // const randomPrice = Math.floor(Math.random() * 1000);
        // form.setFieldsValue({
        //     importecalculado: randomPrice,
        // });

        // Verificar si los campos son válidos
        if (!valueTiempoEstimado || valueTiempoEstimado <= 0) {
            messageApi.open({
                type: 'error',
                content: 'El campo Tiempo Estimado es obligatorio y debe ser mayor que cero.',
                duration: 2,
            });
            return;
        }

        if (!valueDistancia || valueDistancia <= 0) {
            messageApi.open({
                type: 'error',
                content: 'El campo Distancia es obligatorio y debe ser mayor que cero.',
                duration: 2,
            });
            return;
        }

        setSpinning(true);

        let ofertas_mod = JSON.parse(sessionStorage.getItem('ofertas_mod'));

        setSpinTitle('Calculando precio de la oferta...');
        Axios.post("ht/calcularPrecioOferta",
            {
                "idtipotransporte": ofertas_mod.idtipotransporte,
                "distancia": form.getFieldValue('distancia'),//valueDistancia,//ofertas_mod.distancia,
                "tiempoestimado": form.getFieldValue('tiempoestimado'),//valueTiempoEstimado,//ofertas_mod.tiempoestimado,
                "cantidadpasajeros": ofertas_mod.cantidadpasajeros,
                "pernoctar": (form.getFieldValue('pernoctar')) ? 1 : 0//(checkedPernoctar)?1:0
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            //console.log(response.data.data);
            if(response.data){

                form.setFieldsValue({
                    importecalculado: response.data.data,
                    importe: response.data.data,
                });

            }else{
                messageApi.open({
                    type: 'error',
                    content: 'Error al generar el precio de la oferta',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        }).catch(error => {
            setSpinning(false);
            messageApi.open({
                type: 'error',
                content: 'Error al generar el precio de la oferta',
                duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);

        });




    };

    const onSelectTipoTransporte = (value, tipotransporte) => {
        form.setFieldsValue({ caracteristicaomnibus: (tipotransporte.caracteristicaomnibus.replace(/"/g, '')).replace(/,/g, ' / ') });
    };

    const cargarTipoTransporte = () => {
        setLoading(true);
        let aux = [];
        let start = 0;
        let limit = 1000;
        Axios.get(`ht/getTipoTransporte?start=${start}&limit=${limit}`,{
            headers: headers
        })
            .then(response => {
                setLoading(false);
                if(response.data.data.length>0){
                    for(let i=0;i<response.data.data.length;i++){
                        aux.push({
                            id:response.data.data[i].idtipotransporte,
                            nombre:response.data.data[i].denominacion,
                            caracteristicaomnibus:response.data.data[i].caracteristicaomnibus,
                        });
                    }
                }
                setTipotransporte(aux);
            })
            .catch(error => {
                setLoading(false);
                setTipotransporte(aux);
            })
    };

    const onFinish = (values) => {
        setSpinning(true);

        let ofertas_mod = JSON.parse(sessionStorage.getItem('ofertas_mod'));

        setSpinTitle('Guardando datos...');
        Axios.post("ht/generarOferta",
            {
                "idsolicitudviaje": ofertas_mod.idsolicitudviaje,
                "importe": values.importe,
                "observaciones": values.observaciones,
                "condicionesrestriccionespago": values.condicionesrestriccionespago,
                "detallesproveedor": values.detallesproveedor,
                "pernoctar": (values.pernoctar)?1:0,
                "idusuariocreaoferta": idusuarioSession
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
                navigate('/ofertas');

            }else{
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        }).catch(error => {
                setSpinning(false);
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

            });

    };


    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

     const onChangeValue = (e) => {
         //console.log(e.target.checked);
         setCheckedPernoctar(e.target.checked);

     };

    const onChangeTiempoEstimado = (value) => {
        setValueTiempoEstimado(value);
    };

    const onChangeDistancia = (value) => {
        setValueDistancia(value);
    };

    // //componentDisabled, setComponentDisabled

    const disabledEndDate = (current) => {
        return current && current < startDate;
    };

    const disabledStartDate = (current) => {
        // return current && current < endDate;
        return current && current < dayjs().endOf('day');
    };

    const onChange = (dates) => {
        if (dates && dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        }
    };


    // const disabledDate = (current) => {
    //     // Can not select days before today and today
    //     return current && current < dayjs().endOf('day');
    // };
    // const onChange = (date, dateString) => {
    //     console.log(dateString);
    // };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
             <div display="flex" align="middle">
            {/*<h2 style={{textAlign:"center"}}>{title}</h2>*/}
            {/*<p style={{textAlign:"center", margin:0, fontSize:'20px', fontWeight:'bold'}}>{title}</p>*/}
            {contextHolder}
                 <Card title={<h2 style={{height: 20, textAlign:"center"}}>{title}</h2>} style={{ width: '80%', background:"#ffffff" }}>
                 <Form
                     form={form}
                     name="three_column_form"
                     labelCol={{ span: 12 }}
                     wrapperCol={{ span: 16 }}
                     onFinish={onFinish}
                     initialValues={{ cantadultos: 1, cantninnos: 0, cantsillaruedas: 0 }}
                 >
                     <Row gutter={24}>
                         <Col span={8}>
                             {/*columna 1*/}
                             {/* <Form.Item
                                 name="idoferta"
                                 label="ID Oferta"
                             >
                                 <Input readOnly disabled/>
                             </Form.Item> */}

                             <Form.Item
                                 name="origen"
                                 label="Origen"
                             >
                                 <Input readOnly disabled/>
                             </Form.Item>

                             <Form.Item
                                 name="fechaviaje"
                                 label="Fecha de Viaje"
                             >

                                 <DatePicker
                                     style={{width:'100%'}}
                                     disabledDate={disabledStartDate}
                                     disabled
                                     // onChange={onChange}
                                     onChange={(date) => setStartDate(date)}
                                     format='DD/MM/YYYY'
                                 />

                             </Form.Item>

                             <Form.Item
                                 name="tipotransporte"
                                 label="Tipo de Transporte"
                             >

                                 <GlobalSelect disabled onSelect={onSelectTipoTransporte} loading={loading} valueName={'tipotransporte'} data={tipotransporte}/>

                             </Form.Item>

                             <Form.Item
                                 name="caracteristicaomnibus"
                                 label="Características"
                             >
                                 <TextArea
                                     readOnly
                                     disabled
                                     rows={4}
                                     style={{
                                         height: 70,
                                         resize: 'none',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="detallesproveedor"
                                 label="Detalles del Proveedor"
                             >
                                 <TextArea
                                     // readOnly
                                     rows={4}
                                     style={{
                                         height: 120,
                                         resize: 'none',
                                     }}
                                 />
                             </Form.Item>


                         </Col>
                         <Col span={8} >
                             {/*columna 2*/}
                             {/* <Form.Item
                                 name="fecharegistro"
                                 label="Fecha Registro"
                             >

                                 <DatePicker
                                     style={{width:'100%'}}
                                     disabledDate={disabledStartDate}
                                     //defaultValue={currentDate}
                                     disabled
                                     // onChange={onChange}
                                     onChange={(date) => setStartDate(date)}
                                     format='DD/MM/YYYY'
                                 />

                             </Form.Item> */}

                             <Form.Item
                                 name="destino"
                                 label="Destino"
                             >
                                 <Input disabled/>
                             </Form.Item>

                             <Form.Item
                                 name="horasalida"
                                 label="Hora Salida"
                             >
                                 <TimePicker
                                     disabled
                                     onChange={onTimePickerSalidaChange}
                                     placeholder="Seleccione Hora Salida"
                                     //open={horaSalida}
                                     // onOpenChange={onTimePickerSalidaChange}
                                     format={'HH:mm'}
                                     defaultOpenValue={dayjs('00:00', 'HH:mm')}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="pernoctar"
                                 label="pernoctar"
                                 valuePropName="checked"
                                 //initialValue={false}
                                 noStyle
                             >
                                 <Checkbox style={{ marginLeft: '90px' }} onChange={onChangeValue}><p style={{fontWeight:'bold'}}>Pernoctar</p></Checkbox>
                             </Form.Item>

                             <Form.Item wrapperCol={{ offset: 12, span: 16 }} {...tailLayout}>
                                 <Space>

                                     <Button type="primary" onClick={calcularPrecio}>
                                         Calcular Precio
                                     </Button>


                                 </Space>
                             </Form.Item>

                             <Form.Item
                                 style={{
                                     height: 20,
                                     resize: 'none',
                                 }}
                             >
                             </Form.Item>

                             <Form.Item
                                 name="condicionesrestriccionespago"
                                 label="Condiciones/Restricciones"
                             >
                                 <TextArea
                                     // readOnly
                                     rows={4}
                                     style={{
                                         height: 120,
                                         resize: 'none',
                                     }}
                                 />
                             </Form.Item>


                         </Col>
                         <Col span={8} >
                             {/*columna 3*/}
                             <Form.Item
                                 name="tiempoestimado"
                                 label="Tiempo Estimado (horas)"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner el tiempo estimado!',
                                     },
                                 ]}
                             >
                                 <InputNumber
                                     //disabled
                                     min={0}
                                     style={{
                                         width: '100%',
                                     }}
                                     value={valueTiempoEstimado} onChange={onChangeTiempoEstimado}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="distancia"
                                 label="Distrancia (millas)"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner la distancia!',
                                     },
                                 ]}
                             >
                                 <InputNumber
                                     //disabled
                                     min={0}
                                     style={{
                                         width: '100%',
                                     }}
                                     value={valueDistancia} onChange={onChangeDistancia}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="cantidadpasajeros"
                                 label="Cantidad pasajeros"
                             >
                                 <InputNumber
                                     disabled
                                     min={1}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             {/* <Form.Item>

                             </Form.Item> */}
                             <Form.Item
                                 name="importecalculado"
                                 label="Precio Propuesto"
                             >
                                 <InputNumber
                                     disabled
                                     min={0}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="importe"
                                 label="Precio final"
                             >
                                 <InputNumber
                                     min={0}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 style={{
                                     height: 10,
                                     resize: 'none',
                                 }}
                             >
                             </Form.Item>

                             <Form.Item
                                 name="observaciones"
                                 label="Observaciones"
                             >
                                 <TextArea
                                     // readOnly
                                     rows={4}
                                     style={{
                                         height: 120,
                                         resize: 'none',
                                     }}
                                 />
                             </Form.Item>


                         </Col>
                     </Row>

                     <Form.Item wrapperCol={{ offset: 12, span: 16 }} {...tailLayout}>
                         <Space>

                             <Button danger onClick={cancelar}>
                                 Cancelar
                             </Button>
                             <Button type="primary"  htmlType="submit">
                                 Generar Oferta
                             </Button>

                         </Space>
                     </Form.Item>
                 </Form>
                 </Card>

        </div>
        </Spin>

    );
};

export default FormGenOferta;