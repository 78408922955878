import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Spin, Space, Row, Col, InputNumber, Tag, TextArea, Input, Tooltip} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {MdAddHome} from "react-icons/md";
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {DislikeOutlined, LikeOutlined, HistoryOutlined, CloseCircleOutlined, CarOutlined, SolutionOutlined, EditOutlined } from '@ant-design/icons';
import {IoReload, IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import Search from "antd/es/input/Search";
import _ from "lodash";
import {FormattedMessage} from "react-intl";



export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

// const { Option } = Select;

const Oferta = () => {

    if(sessionStorage.getItem('ofertas_mod'))
       sessionStorage.removeItem('ofertas_mod');

    const [bottom, setBottom] = useState('bottomCenter');

    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);

    const [form] = Form.useForm();

    const { TextArea } = Input;

    const navigate = useNavigate();

    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando datos...");

    const [messageApi, contextHolder] = message.useMessage();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [loading] = useState(false);

    const [planificarButtonDisabled, setPlanificarButtonDisabled] = useState(true);
    const [rechazarSolButtonDisabled, setRechazarSolButtonDisabled] = useState(true);
    const [generarOfertaButtonDisabled, setGenerarOfertaButtonDisabled] = useState(true);
    const [editarOfertaButtonDisabled, setEditarOfertaButtonDisabled] = useState(true);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: 'Categoría Servicio',
            dataIndex: 'categoriaservicio',
            fixed: 'left',
            width: 160,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.categoriaservicio), _.isEqual),
            onFilter: (value, record) => record.categoriaservicio.startsWith(value)
        },{
            title: 'Estado Solicitud',
            dataIndex: 'estadosolicitud',
            fixed: 'left',
            width: 170,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.estadosolicitud[0]), _.isEqual),
            onFilter: (value, record) => record.estadosolicitud[0].startsWith(value),
            render: (tags) => (
                <span>
              {tags.map((tag) => {
                  let color;
                  switch (tag) {
                      case 'Pendiente':
                          color = '#ff8c00';
                          break;
                      case 'Rechazada':
                          color = '#f03e3e';
                          break;
                      case 'Cancelada':
                          color = '#E94B3C';
                          break;
                      case 'Planificada':
                          color = '#51cf66';
                          break;
                      case 'Ofertada':
                          color = '#5c7cfa';
                          break;
                      case 'Confirmada':
                            color = '#B565A7';
                            break;
                      default:
                          color = '#ff8c00';
                  }
                  return (
                      <Tag color={color} key={tag}>
                          {tag.toUpperCase()}
                      </Tag>
                  );
              })}
            </span>
            ),
        },{
            title: 'Estado Oferta',
            dataIndex: 'estadooferta',
            fixed: 'left',
            width: 130,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.estadooferta[0]), _.isEqual),
            onFilter: (value, record) => record.estadooferta[0].startsWith(value),
            render: (tags) => (
                <span>
              {tags.map((tag) => {
                  let color;
                  switch (tag) {
                      case 'Aceptada':
                          color = '#51cf66';
                          break;
                      case 'Ofertada':
                          color = '#5c7cfa';
                          break;
                      case 'Rechazada':
                          color = '#f03e3e';
                          break;
                      case 'Confirmada':
                            color = '#B565A7';
                            break;
                      default:
                          color = '#ff8c00';
                  }
                  return (
                      <Tag color={color} key={tag}>
                          {tag.toUpperCase()}
                      </Tag>
                  );
              })}
            </span>
            ),

        },{
            title: 'Origen',
            dataIndex: 'origen',
            fixed: 'left',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.origen), _.isEqual),
            onFilter: (value, record) => record.origen.startsWith(value)
        },{
            title: 'Destino',
            dataIndex: 'destino',
            fixed: 'left',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.destino), _.isEqual),
            onFilter: (value, record) => record.destino.startsWith(value)
        },{
            title: 'Fecha Viaje',
            dataIndex: 'fechaviaje',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.fechaviaje), _.isEqual),
            onFilter: (value, record) => record.fechaviaje.startsWith(value)
        },{
            title: 'Hora Salida',
            dataIndex: 'horasalida',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.horasalida), _.isEqual),
            onFilter: (value, record) => record.horasalida.startsWith(value)
        },{
            title: 'Tipo Transporte',
            dataIndex: 'tipotransporte',
            filterSearch: true,
            width: 160,
            filters: _.uniqWith(filterData(data)(i => i.tipotransporte), _.isEqual),
            onFilter: (value, record) => record.tipotransporte.startsWith(value),
        },{
            title: 'Adultos',
            dataIndex: 'cantadultos',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cantadultos), _.isEqual),
            onFilter: (value, record) => record.cantadultos.startsWith(value)
        },{
            title: 'Niños',
            dataIndex: 'cantninnos',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cantninnos), _.isEqual),
            onFilter: (value, record) => record.cantninnos.startsWith(value)
        },{
            title: 'Silla de Ruedas',
            dataIndex: 'cantsillaruedas',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cantsillaruedas), _.isEqual),
            onFilter: (value, record) => record.cantsillaruedas.startsWith(value)
        },{
            title: 'Ofertada',
            dataIndex: 'ofertada',
            render: (text) => (text === 1)?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Importe',
            dataIndex: 'importeoferta',
            // fixed: 'right',
            render: (tags) => (
                <span>
              {tags.map((tag) => {
                  let color= '#834e56';
                  tag = '$'+' '+tag;
                  return (
                      <Tag color={color} key={tag}>
                          {tag.toUpperCase()}
                      </Tag>
                  );
              })}
            </span>
            ),
        },{
            title: 'Estado Pago',
            dataIndex: 'estadopago',
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Solicita',
            dataIndex: 'correoregistro',
            width: 230,
        },{
            title: 'Teléfono',
            dataIndex: 'telefonousuariosolic',
        }/*,{
            title: 'caracteristicaomnibus',
            dataIndex: 'caracteristicaomnibus',
        }*/
    ];

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    useEffect(() => {
        recargar();
    }, []);  

    const recargar = () => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        // let start = 0;
        // let limit = 1000;

        Axios.post("ht/getOfertas",
            {
                //"idcategoriaservicio": 1
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            // console.log('usuarios', response.data);
            if(response.data.data.length>0){
                for(let i=0;i<response.data.data.length;i++){
                    aux.push({
                        key:response.data.data[i].idsolicitudviaje,
                        idsolicitudviaje:response.data.data[i].idsolicitudviaje,
                        idcategoriaservicio:response.data.data[i].idcategoriaservicio,
                        categoriaservicio:response.data.data[i].categoriaservicio,
                        // idviajecharterfecha:response.data.data[i].idviajecharterfecha,
                        origen:response.data.data[i].origen,
                        destino:response.data.data[i].destino,
                        fechaviaje:response.data.data[i].fechaviaje,
                        horasalida:response.data.data[i].horasalida,
                        fecharegistro:response.data.data[i].fecharegistro,
                        correoregistro:response.data.data[i].correoregistro,
                        telefonousuariosolic:response.data.data[i].telefonousuariosolic,
                        tipotransporte:response.data.data[i].tipotransporte,
                        idtipotransporte:response.data.data[i].idtipotransporte,
                        cantninnos:response.data.data[i].cantninnos,
                        cantadultos:response.data.data[i].cantadultos,
                        cantidadpasajeros:response.data.data[i].cantidadpasajeros,
                        cantsillaruedas:response.data.data[i].cantsillaruedas,
                        ofertada:response.data.data[i].ofertada,
                        estadooferta:[response.data.data[i].estadooferta],
                        importeoferta:[response.data.data[i].importeoferta],
                        estadopago:response.data.data[i].estadopago,
                        idestadoofertapago:response.data.data[i].idestadoofertapago,
                        idestadosolicitud:response.data.data[i].idestadosolicitud,
                        estadosolicitud:[response.data.data[i].estadosolicitud],
                        caracteristicaomnibus:response.data.data[i].caracteristicaomnibus.replace(/"/g, ''),
                        //pernoctar:(response.data.data[i].pernoctar === "1"),
                        pernoctar:response.data.data[i].pernoctar,
                        condicionesrestriccionespago: response.data.data[i].condicionesrestriccionespago,
                        detallesproveedor: response.data.data[i].detallesproveedor,
                        importe: response.data.data[i].importeoferta,
                        observaciones: response.data.data[i].observaciones,
                        // caracteristicaomnibus:(response.data.data[i].caracteristicaomnibus.replace(/"/g, '')).replace(/,/g, ' / ')
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        }).catch(error => {
                setSpinning(false);
            });
    };

    const getInfoForm = async (id) => {
        for(let i=0;i<data.length;i++){
            if(data[i].idsolicitudviaje === id){
                return data[i];
            }
        }
        return [];
    };

    const modificar = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        sessionStorage.setItem('ofertas_mod', JSON.stringify(row));
    };

    const eliminar = (e) => {
        Modal.confirm({
            title: 'Eliminar datos!',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea eliminar los datos seleccionados?</span>
                </div>
            ),
            onOk() {
                setSpinTitle("Eliminando datos...");
                setSpinning(true);
                Axios.delete('ht/deleteSolicitudViaje',
                    {
                      data: {
                        "idsolicitudviaje": selectedRowKeys
                      },
                      headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);

                    });
            },
        });
    };


    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            let aux = [];
            original.forEach(function(option) {
                if (option.fechaviaje.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };


    const onSelectChange = newSelectedRowKeys => {
        setSelectedRowKeys(newSelectedRowKeys);
        if (newSelectedRowKeys.length === 1) {
            const selectedRow = data.find(row => row.key === newSelectedRowKeys[0]);
            // console.log("Selected Row: ", selectedRow);
            if (selectedRow) {
                 //console.log("idestadosolicitud: ", selectedRow.idestadosolicitud);
                setPlanificarButtonDisabled(selectedRow.idestadoofertapago != 3);
                setRechazarSolButtonDisabled(selectedRow.idestadosolicitud == 1);
                setGenerarOfertaButtonDisabled(selectedRow.idestadoofertapago == 3 || selectedRow.idestadoofertapago == 4);
                setEditarOfertaButtonDisabled(selectedRow.idestadoofertapago != 0 && selectedRow.idestadoofertapago != 1);
            }
        }else{
            setPlanificarButtonDisabled(true);
            setRechazarSolButtonDisabled(false);
            setGenerarOfertaButtonDisabled(true);
            setEditarOfertaButtonDisabled(true);
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const selectRowUpdate  = async (record) => {
        setSelectedRowKeys([record.key]);
        form.resetFields();
        let row = [];
        for(let i=0;i<data.length;i++){
            if(data[i].idsolicitudviaje === record.key){
                row = data[i];
            }
        }

        // console.log('ROW : ', row);
       
        sessionStorage.setItem('ofertas_mod', JSON.stringify(row));
        // navigate(`/editviajepersonalizado/${record.key}`)
        
    };


    const [visible, setVisible] = useState(false);
    const [numberValue, setNumberValue] = useState(0);
    const [visibleCancel, setVisibleCancel] = useState(false);
    const [observacion, setObservacion] = useState('');


    const showModal = () => {
        setNumberValue(0);
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);

        Axios.post("ht/generarOferta",
            {
                "idsolicitudviaje": selectedRowKeys[0],
                "importe": numberValue,
                "idusuariocreaoferta": JSON.parse(sessionStorage.getItem('info_user')).idusuario
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

            }else{
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
            .catch(error => {
                setSpinning(false);
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

            });

        recargar();

    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onChange = (value) => {
        setNumberValue(value);
    };

    const showCancelModal = () => {
        setObservacion('');
        setVisibleCancel(true);
    };

    const handleCancelarSolicitud = () => {
        setVisibleCancel(false);
    };

    const handleOkSolicitud = () => {
        setVisibleCancel(false);

        Axios.post("ht/cancelarSolicitud",
            {
                "idsolicitudviaje": selectedRowKeys[0],
                "idestadosolicitud": 1, // cancelada por el Dispatcher [1]
                "observacion": observacion,
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

            }else{
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
            .catch(error => {
                setSpinning(false);
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar los datos',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

            });

        recargar();

    };


    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
        <div>
            {/*<h2 style={{textAlign:"center"}}>Configurar Ruta Charter</h2>*/}
            <p style={{textAlign:"center", margin:0, fontSize:'20px', fontWeight:'bold'}}>Ofertas</p>
            {contextHolder}
            <div
                style={{
                    marginBottom: 10,
                }}
            >
                {contextHolder}
                <Space direction='horizontal'>
                    <Link to="/formgeneraroferta">
                        <Tooltip title="Generar Oferta">
                            <Button style={{marginRight:"0px", marginLeft:"10px"}} type="primary" shape="circle" className="button" icon={<SolutionOutlined />} onClick={modificar} disabled={generarOfertaButtonDisabled}>
                                {/* Generar Oferta */}
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/formmodificaroferta">
                        <Tooltip title="Modificar Oferta">
                            <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" className="button" icon={<EditOutlined />} onClick={modificar} disabled={editarOfertaButtonDisabled}>
                                {/* Modificar Oferta */}
                            </Button>
                        </Tooltip>
                    </Link>
                    <Tooltip title=" Rechazar Solicitud">
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" danger className="button" icon={<CloseCircleOutlined />} disabled={rechazarSolButtonDisabled || selectedRowKeys.length !== 1} onClick={showCancelModal}>
                       {/*  Rechazar Solicitud */}
                        </Button>
                    </Tooltip>
                    <Link to="/verhistorial">
                        <Tooltip title="Ver Historial Ofertas">
                            <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" className="button" icon={<HistoryOutlined />} onClick={modificar} disabled={loading || selectedRowKeys.length !== 1}>
                            </Button>
                        </Tooltip>
                    </Link>
                    {/*<Link to="/vervehiculos">*/}
                    {/*    <Tooltip title="Planificar viaje">*/}
                    {/*        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" className="button" icon={<CarOutlined />} onClick={modificar} disabled={planificarButtonDisabled} >*/}
                    {/*            /!* Planificar viaje *!/*/}
                    {/*        </Button>*/}
                    {/*    </Tooltip>*/}
                    {/*</Link>*/}
                        {/*<Button type="primary" danger className="button" icon={<AiFillDelete />} onClick={eliminar}*/}
                        {/*        disabled={!hasSelected || loading}>*/}
                        {/*    Eliminar*/}
                        {/*</Button>*/}
                    <Tooltip title={<FormattedMessage id="sistema_btn_actualizar" defaultMessage="Actualizar"/>}>
                        <Button style={{marginRight:"0px", marginLeft:"0px"}} type="primary" shape="circle" className="button" icon={<IoReload />} onClick={recargar} loading={loading || loading}>
                            {/* Recargar */}
                        </Button>
                    </Tooltip>

                    <Search
                        placeholder="Fecha Viaje"
                        enterButton
                        style={{
                            width: 200,
                        }}
                        size="middle"
                        onSearch={onSearch}
                        onChange={(e) => {
                            filterLocal(e.target.value);
                        }}
                    />
                </Space>
            </div>

            <Modal
                title="Generar Oferta"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                style={{ textAlign: 'center' }}
                centered
                okButtonProps={{ disabled: numberValue <= 0 }}
            >
                <p>Por favor introduzca el importe de la oferta que desea realizarle al cliente:</p>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <InputNumber
                        min={0}
                        // max={100}
                        value={numberValue}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        onChange={onChange} />
                </div>
            </Modal>

            <Modal
                title="Rechazar Solicitud"
                visible={visibleCancel}
                onOk={handleOkSolicitud}
                onCancel={handleCancelarSolicitud}
                style={{ textAlign: 'center' }}
                centered
                okButtonProps={{ disabled: observacion.trim() === '' }}
            >
                <p>Observación:</p>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextArea
                        placeholder="Escribe aquí..."
                        rows={4}
                        style={{
                            height: 100,
                            resize: 'none',
                        }}
                        value={observacion}
                        onChange={(e) => setObservacion(e.target.value)}
                        // onChange={onChangeCancelSolicitud}
                    />
                </div>
            </Modal>
 

            <Row>
                {/*<Col span={24}>*/}
                    <Table size="small"
                           // scroll={{
                           //     x: 2100,
                           // }}
                           scroll={{ x: 'max-content' }}
                           //bordered={true}
                           pagination={{
                            position: [bottom],
                            pagination: {
                                current: 1,
                                pageSize: 10,
                            }}}
                           rowSelection={rowSelection}
                           columns={columns} dataSource={data}
                           onRow={(record) => {
                               return {
                                   onDoubleClick: async () => {
                                       selectRowUpdate(record);
                                   }
                               }
                           }}/>
                {/*</Col>*/}
            </Row>


         </div>
        </Spin>
    );
};
export default Oferta;