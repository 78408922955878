import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import {Form, Input, Space, Button, message, Spin, InputNumber, Select} from "antd";
import axios from 'axios';
import GlobalSelect from "../../generales/GlobalSelect";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormOmnibus = ({accion}) => {

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const validateCapacidad = (rule, value, callback) => {
        const phoneRegex = /^(0|[1-9]\d*)$/;
        if (accion !== "pass" && !phoneRegex.test(value)) {
          callback('El capacidad solo debe contener números');
        } else {
          callback();
        }
    }; 


    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando información del ómnibus...");

    const title = (accion === "mod")?"Modificar Vehículo":"Adicionar Vehículo";

    const [tipotransporte, setTipotransporte] = useState([]);

    const [chofer, setChofer] = useState([]);

    const [features, setFeatures] = useState([]);

    const navigate = useNavigate();

    const { id } = useParams();

    const [form] = Form.useForm();

    useEffect(() => {
        
        cargarTipoTransporte();
        cargarChofer();
        // loadFeatures();

        if(accion === "mod"){
            if(!sessionStorage.getItem('omnibus_mod')){
                navigate('/omnibus');
            }else{
                let omnibus_mod = JSON.parse(sessionStorage.getItem('omnibus_mod'));

                //console.log('omnibus_mod', omnibus_mod);
    
                if(id !== omnibus_mod.idomnibus)
                   navigate('/omnibus');
        
                form.setFieldsValue({
                        "denominacion": omnibus_mod.denominacion,
                        "tipotransporte": omnibus_mod.idtipotransporte,
                        "capacidad": omnibus_mod.capacidad,
                        "chapa": omnibus_mod.chapa,
                        "nombre": omnibus_mod.idusuario
                    /*,
                        "idcaracteristicaomnibus": JSON.parse(omnibus_mod.idcaracteristicaomnibus)*/
                    });


            }    
        }
    }, []);

    const tailLayout = {
        wrapperCol: { marginTop:10, offset: 8, span: 16 },
      };

    const cargarTipoTransporte = () => {
        setLoading(true);
        let aux = [];
        let start = 0;
        let limit = 1000;
        Axios.get(`ht/getTipoTransporte?start=${start}&limit=${limit}`,{
            headers: headers
          })
            .then(response => {
                setLoading(false);
                if(response.data.data.length>0){
                    for(let i=0;i<response.data.data.length;i++){
                        aux.push({
                            id:response.data.data[i].idtipotransporte,
                            nombre:response.data.data[i].denominacion,
                        });
                    }
                }
                setTipotransporte(aux);
            })
            .catch(error => {
                setLoading(false);
                setTipotransporte(aux);
                //console.log("Error",error);
            })
    };

    const cargarChofer = () => {
        setLoading(true);
        let aux = [];
        let start = 0;
        let limit = 1000;

        Axios.post("ht/getUssers",
            {
                "start": start,
                "limit": limit,
                "filtros":{
                    "idrol": 3
                }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.data.length>0){
                for(let i=0;i<response.data.data.length;i++){
                    aux.push({
                        id:response.data.data[i].idusuario,
                        idchofer:response.data.data[i].idusuario,
                        nombre:response.data.data[i].correo
                    });
                }
            }
            setChofer(aux);
        }).catch(error => {
            setLoading(false);
            setChofer(aux);
            })
    };

    // const loadFeatures = () => {
    //     setSpinning(true);
    //     setSpinTitle("Cargando datos...")
    //     let aux = [];
    //     let start = 0;
    //     let limit = 1000;
    //
    //     Axios.get(`ht/getCaractTransporte?start=${start}&limit=${limit}`, {
    //         headers: headers
    //     })
    //         .then(response => {
    //             setSpinning(false);
    //             // console.log(response.data.data);
    //             if(response.data.data.length > 0){
    //                 for(var i=0;i<response.data.data.length;i++){
    //                     aux.push({
    //                         id: response.data.data[i].idcaracteristicaomnibus,
    //                         denominacion: response.data.data[i].denominacion,
    //                         // denominacion: response.data.data[i].denominacion.replace(/"/gi, "\""),
    //                     });
    //                 }
    //             }
    //             setFeatures(aux);
    //         })
    //         .catch(error => {
    //             setSpinning(false);
    //             setFeatures(aux);
    //             console.log("Error",error);
    //         })
    // };

    const cancelar = (values) => {
        navigate('/omnibus');
    };

    const onFinish = (values) => {
        setSpinning(true);
        if(accion === "add"){
            console.log(values);
            setSpinTitle('Guardando datos...');
            Axios.post("ht/addOmnibus",
                    {
                        "denominacion": values.denominacion,
                        "idtipotransporte": values.tipotransporte,
                        "capacidad": values.capacidad,
                        "chapa": values.chapa,
                        "idchofer": values.nombre
                        /*,
                        "idcaracteristicaomnibus": values.idcaracteristicaomnibus*/
                    },
                    {
                        headers: headers
                      }
                ).then(response => {
                  setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        navigate('/omnibus');
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al guardar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
            })
                .catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar datos',
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);


                });
        }else if(accion === "mod"){
            setSpinTitle('Modificando datos...');
            Axios.post("ht/updateOmnibus",
            {
                "idomnibus": id,
                "denominacion": values.denominacion,
                "idtipotransporte": values.tipotransporte,
                "capacidad": values.capacidad,
                "chapa": values.chapa,
                "idchofer": values.nombre
                /*,
                "idcaracteristicaomnibus": values.idcaracteristicaomnibus*/
            },
            {
                headers: headers
              }
        ).then(response => {
          setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

                navigate('/omnibus');
            }else{
                messageApi.open({
                    type: 'error',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
        .catch(error => {
            setSpinning(false);
            messageApi.open({
                type: 'error',
                content: 'Error al guardar datos',
                duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);
        });
        }

      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
             <div display="flex" align="middle">
            <h2 style={{textAlign:"center"}}>{title}</h2>
            {contextHolder}
             <Form
                form={form}
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 10,
                  }}
                name="form_in_modal"
                initialValues={{
                    activo: 1,
                }}
            >
                <Form.Item
                   name="denominacion"
                   label="Denominación"
                    rules={[
                      {
                         required: true,
                         message: 'Por favor debe poner la denominación!',
                       },
                 ]}
               >
                    <Input /> 
                </Form.Item> 
                
                <Form.Item
                    name="tipotransporte"
                    label="Tipo de Transporte"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor seleccione el tipo de transporte del servicio!',
                        },
                    ]}>

                    <GlobalSelect loading={loading} valueName={'tipotransporte'} data={tipotransporte}/>
                
                </Form.Item>

                <Form.Item
                            name="capacidad"
                            label="Capacidad"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor debe poner la capacidad!',
                                }
                            ]}
                        >
                            <InputNumber
                                 min={1}
                                 max={99}
                                 step={1}
                                 style={{width:'100%'}}
                             />
                        </Form.Item>

                        <Form.Item
                             name="chapa"
                             label="Chapa"
                              rules={[
                                {
                                   required: true,
                                   message: 'Por favor debe poner la chapa!',
                                 },
                                 {max: 10, message: 'La chapa solo puede tener 10 caracteres'},
                           ]}
                         >
                            <Input onInput={e => e.target.value = e.target.value.toUpperCase()} /> 
                        </Form.Item>

                 <Form.Item
                     name="nombre"
                     label="Chofer"
                     rules={[
                         {
                             required: true,
                             message: 'Por favor seleccione el chofer del vehículo!',
                         },
                     ]}>

                     <GlobalSelect loading={loading} valueName={'nombre'} data={chofer}/>

                 </Form.Item>

                        {/*<Form.Item*/}
                        {/*     name="idcaracteristicaomnibus"*/}
                        {/*     label="Característica"*/}
                        {/*     rules={[*/}
                        {/*         {*/}
                        {/*             // required: true,*/}
                        {/*             message: 'Debe seleccionar al menos una opción!',*/}
                        {/*             type: 'array',*/}
                        {/*         },*/}
                        {/*     ]}*/}
                        {/* >*/}
                        {/*     <Select mode="multiple" placeholder="Seleccione al menos una opción!" loading={loading}>*/}
                        {/*         {features.map((features) => (*/}
                        {/*             <Select value={features.id}>*/}
                        {/*                 {features.denominacion}*/}
                        {/*             </Select>*/}
                        {/*         ))}*/}
                        {/*     </Select>*/}
                        {/* </Form.Item>*/}


                    <Form.Item {...tailLayout}>
                                <Space>
                                  
                                    <Button danger onClick={cancelar}>
                                        Cancelar
                                      </Button>

                                    <Button type="primary"  htmlType="submit">
                                        Guardar
                                    </Button>
                                  
                                 
                                </Space>
                    </Form.Item>
                
            </Form>

        </div>
        </Spin>
       
    );
};

export default FormOmnibus;